import _ from 'lodash'

export async function uploadFile(
  url: string,
  fields: any,
  file: File
) {
  const formData = new FormData()
  _.forOwn(JSON.parse(fields), (value, key) => formData.set(key, value))
  formData.set('file', file)
  const response = await fetch(url, {method: 'POST', body: formData})
  if (!response.ok) throw new Error()
}
