import { API } from '@indieocean/apidef'
import { fGet } from '@indieocean/utils'
import _ from 'lodash'
import React, { Suspense, useCallback, useMemo, useState } from 'react'
import { usePaginationFragment } from 'react-relay'
import { graphql, RecordSourceSelectorProxy } from 'relay-runtime'
import { GQL } from '../../../../Generated/GQL/TypesFromSDL'
import { CenteredCircleLoadTrigger } from '../../../Common/Tools/CenteredCircleLoadTrigger'
import { CenteredCircleProgress } from '../../../Common/Tools/CenteredCircleProgress'
import { PublishedPost } from '../../Post/Post'
import { BookLive } from '../../Store/Book/Home/Book'
import { AddComment } from './AddComment'
import { Comment, isDeletedComment } from './Comment'
import { Comments_comments$key } from './__generated__/Comments_comments.graphql'

const fragment = graphql`
  fragment Comments_comments on Comments
  @refetchable(queryName: "CommentsPaginationQuery") {
    id
    count
    listOutOfBand {
      ...Comment_comment @relay(mask: false)
    }
    list(after: $commentsStartCursor, first: $commentsPageSize)
      @connection(key: "Comments_list") {
      edges {
        node {
          ...Comment_comment @relay(mask: false)
        }
      }
    }
  }
`
// This is a grand hack to work around the inability to update connections.
export function addToCommentListOutOfBand(
  cache: RecordSourceSelectorProxy,
  targetKey: API.PostKey | API.BookKey,
  commentKey: API.CommentKey
) {
  const commentsRecord = fGet(cache.get(API.GQLIds.comments(targetKey)))
  const count = fGet(commentsRecord.getValue('count')) as number
  commentsRecord.setValue(count + 1, 'count')
  const listOutOfBandRecord = fGet(
    commentsRecord.getLinkedRecords('listOutOfBand')
  )
  const commentRecord = fGet(cache.get(API.GQLIds.comment(commentKey)))
  commentsRecord.setLinkedRecords(
    [commentRecord, ...listOutOfBandRecord],
    'listOutOfBand'
  )
}

export const commentsQueryArgs = {
  commentsStartCursor: API.MaterializedPath.serialize([0, -1]),
  commentsPageSize: 20,
}

export type CommentsProps = {
  className?: string
  target: PublishedPost | BookLive
  commentsKey: Comments_comments$key
  labelSize: 'text-xl' | 'text-2xl' | 'text-lg'
}
export const Comments = React.memo(
  ({className = '', ...props}: CommentsProps) => {
    return (
      <div className={`${className} mt-[-4.31rem] pt-[4.31rem]`} id="comments">
        <Suspense
          fallback={
            <div className="">
              <_Heading {...props} />
              <div className="relative h-28">
                <CenteredCircleProgress />
              </div>
            </div>
          }
        >
          <_Comments {...props} />
        </Suspense>
      </div>
    )
  }
)

const _Heading = React.memo(
  ({labelSize}: {labelSize: CommentsProps['labelSize']}) => (
    <h2 className={`${labelSize} font-semibold `}>Comments</h2>
  )
)

const _Comments = React.memo(
  ({target, commentsKey, labelSize}: Omit<CommentsProps, 'className'>) => {
    const {
      data: dataIn,
      hasNext,
      loadNext,
    } = usePaginationFragment(fragment, commentsKey)
    const {list, listOutOfBand, count} =
      dataIn as unknown as GQL.Comments_Comments

    const comments = useMemo(
      () =>
        _trim(
          _.uniqBy(
            _.sortBy([...listOutOfBand, ...list.edges.map(x => x.node)], x =>
              API.MaterializedPath.serialize(x.path)
            ),
            x => x.id
          )
        ),
      [list, listOutOfBand]
    )

    const [showAddComment, setShowAddComment] = useState(false)

    const handleLoadNext = useCallback(
      () => loadNext(commentsQueryArgs.commentsPageSize),
      [loadNext]
    )
    return (
      <div className="">
        <div className="flex gap-x-2 items-end">
          <_Heading {...{labelSize}} />
          <h2 className="text-lg lighten-2">({count})</h2>
        </div>

        {showAddComment ? (
          <AddComment
            className="mt-5"
            target={target}
            parentPath={[0]}
            onDone={() => setShowAddComment(false)}
          />
        ) : (
          <button
            className="mt-5 border-b-2 border-inputBorder"
            onClick={() => setShowAddComment(true)}
          >
            Add a Comment
          </button>
        )}
        <div className="mt-5">
          {comments.map(comment => (
            <Comment
              key={comment.id}
              className="my-10"
              {...{comment, target}}
            />
          ))}
        </div>
        {hasNext && (
          <div className="relative w-full h-20">
            <CenteredCircleLoadTrigger onTrigger={handleLoadNext} />
          </div>
        )}
      </div>
    )
  }
)

const _trim = (comments: Comment[]) =>
  comments.filter((comment, i) => {
    if (!isDeletedComment(comment)) return true
    if (i === comments.length - 1) return false
    const nextComment = comments[i + 1]
    return API.MaterializedPath.isChild(nextComment.path, comment.path)
  })
