import {API} from '@indieocean/apidef'
import {fGet} from '@indieocean/utils'
import React, {useEffect, useState} from 'react'
import {graphql} from 'relay-runtime'
import {CircleProgressIfRunning} from '../../../Common/Tools/CircleProgressIfRunning'
import { useCreateStoreOnNotLiveStore } from '../../../Common/Tools/UseCreateStoreOrAction'
import {useGQLMutation} from '../../../Common/Tools/UseGQLMutation'
import {useGlobalToasts} from '../../../Common/WithGlobalToasts'
import {
  isUserWithLiveStore,
  UserWithLiveStore,
  useUser,
} from '../../../Common/WithUser'
import {PublishedPost} from '../../Post/Post'
import {BookLive} from '../../Store/Book/Home/Book'
import {CompactEditor} from '../Editor/CompactEditor/CompactEditor'
import {Editor, EditorRenderProps} from '../Editor/Editor'
import {addToCommentListOutOfBand} from './Comments'
import {AddCommentMutation} from './__generated__/AddCommentMutation.graphql'

const mutation = graphql`
  mutation AddCommentMutation($args: StoreAddCommentArgs!, $userId: String!) {
    storeAddComment(args: $args) {
      id
      ...Comment_comment
    }
  }
`

export const AddComment = React.memo(
  ({
    className = '',
    target,
    parentPath,
    onDone,
  }: {
    className?: string
    target: PublishedPost | BookLive
    parentPath: number[]
    onDone: () => void
  }) => {

    const [content, setContent] = useState(
      JSON.stringify({type: 'doc', content: [{type: 'paragraph'}]})
    )
    const {errorToast, infoToast} = useGlobalToasts()

    const [commit, isRunning] = useGQLMutation<AddCommentMutation>(
      mutation,
      'soft'
    )

    const handleAdd = (props: EditorRenderProps, user: UserWithLiveStore) => {
      const {userId} = user
      const snippet = props.getSnippet(10)
      if (snippet.length === 0) {
        errorToast('Comment has no content.')
        return
      }
      const targetKey: {bookKey: API.BookKey} | {postKey: API.PostKey} =
        target.__typename === 'Book'
          ? {bookKey: target.bookKey}
          : {postKey: fGet(target.postKey)}
      commit({
        variables: {
          args: {
            userId,
            content: props.contentStr(),
            target: targetKey,
            parent: parentPath,
          },
          userId,
        },
        updater: cache => {
          const commentRecord = cache.getRootField('storeAddComment')
          const commentKey = API.GQLIds.invComment(commentRecord.getDataID())
          addToCommentListOutOfBand(
            cache,
            'bookKey' in targetKey ? targetKey.bookKey : targetKey.postKey,
            commentKey
          )
        },
        onCompleted: () => {
          infoToast('Comment added.')
          onDone()
        },
      })
    }


    const user = useCreateStoreOnNotLiveStore()
    if (!user) {
      return <></>
    }

    return (
      <Editor
        userIdOfPostAuthor={user.userId}
        data={{books: [], stores: [], content}}
        editable
        type="minimal"
        isExternalSaved
      >
        {props => (
          <CircleProgressIfRunning
            className={`${className}`}
            isRunning={isRunning}
          >
            <CompactEditor propsFromEditor={props} title={null} />

            <div className="mt-3 flex ">
              <button
                className="btn-sm btn-dark"
                onClick={() => handleAdd(props, user)}
              >
                Comment
              </button>
              <button className="btn-sm underline" onClick={onDone}>
                Cancel
              </button>
            </div>
          </CircleProgressIfRunning>
        )}
      </Editor>
    )
  })

