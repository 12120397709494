import {faTrash} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {API} from '@indieocean/apidef'
import Link from 'next/link'
import React from 'react'
import {ModalBase} from '../../../../Common/Modal/ModalBase'
import {useAppPathFn} from '../../../../Common/Tools/UseAppPathFn'

export const EditorBookControl = React.memo(
  ({
    bookPath,
    onCancel,
    onDelete,
  }: {
    bookPath: API.Path
    onCancel: () => void
    onDelete: () => void
  }) => {
    const pathFn = useAppPathFn()
    return (
      <ModalBase open={true} onClose={onCancel}>
        <div className="grid " style={{grid: 'auto / 1fr auto'}}>
          <Link href={pathFn(bookPath)}>
            <a className="a-btn outline-none py-2 sm:py-0"> Open Link </a>
          </Link>
          <button className=" py-2 sm:py-0 px-4" onClick={onDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </ModalBase>
    )
  }
)
