import { API } from '@indieocean/apidef'
import { fGet } from '@indieocean/utils'
import { useURLParam } from '../../../../Utils/UseURLParam'
import { GQLFetchError } from '../../../Common/WithRelay'

export const useBookSlugOrIdURLParam = () => {
  const bookSlug = fGet(useURLParam('bookSlug')).toLowerCase()
  if (bookSlug.endsWith('_')) {
    const bookId = bookSlug.substr(0, bookSlug.length - 1)
    const decoded = API.Decode.dbId().run(bookId)
    if (!decoded.ok) throw new GQLFetchError('NotFound')
    return {bookId}
  } else {
    const decoded = API.Decode.genericSlug().run(bookSlug)
    if (!decoded.ok) throw new GQLFetchError('NotFound')
    return {bookSlug}
  }
}
