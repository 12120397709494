import {API} from '@indieocean/apidef'
import {NextRouter, useRouter} from 'next/router'
import { useEffect } from 'react'
import {isUserWithLiveStore, UserWithLiveStore, useUser} from '../WithUser'
import {useAppPathFn} from './UseAppPathFn'

export const useCreateStoreOrAction = (
  fn: (user: UserWithLiveStore) => void
) => {
  const pathFn = useAppPathFn()
  const action = useChooseAction()
  const router = useRouter()

  return action.redirect
    ? () => void router.push(pathFn(action.path))
    : () => fn(action.user)
}

const _dest = (router: NextRouter) => {
  const destURL = new URL(`https://www.dummy.com${router.asPath}`)
  destURL.searchParams.delete('for')
  return `${destURL.pathname}${destURL.search}`
}

export function useCreateStoreOnNotLiveStore() {
  const action = useChooseAction()

  const pathFn = useAppPathFn()
  const router = useRouter()
  useEffect(()=>{
    if(!action.redirect) return
    void router.push(pathFn(action.path))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return action.redirect ? null : action.user
}

function useChooseAction() {
  const user = useUser()
  const router = useRouter()
  const getStartedPath = API.Path.getStarted({dest: _dest(router)})
  return !user
    ? ({
        redirect: true,
        path: API.Path.login({dest: getStartedPath.toString()}),
      } as const)
    : !isUserWithLiveStore(user)
    ? ({redirect: true, path: getStartedPath} as const)
    : ({redirect: false, user} as const)
}
