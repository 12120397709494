import {Dialog} from '@headlessui/react'
import React, {ReactNode} from 'react'

export const ModalBase = React.memo(
  ({
    open = true,
    children,
    onClose = () => {},
  }: {
    open?: boolean
    children: ReactNode
    onClose?: () => void
  }) => {
    return (
      <Dialog open={open} onClose={onClose} className="modal-base">
        <Dialog.Overlay className="modal-overlay" />
        <div
          className="relative bg-pageBG text-pageFG z-10 rounded-lg max-w-2xl p-2 sm:p-4 shadow-xl m-2 sm:m-4"
          style={{minWidth: 'min(calc(100vw - 20px), 500px)'}}
        >
          {children}
        </div>
      </Dialog>
    )
  }
)
