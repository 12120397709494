/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PostGetImageUploadURLArgs = {
    ext: string;
    userId: string;
};
export type EditorImageInputGetURLMutationVariables = {
    args: PostGetImageUploadURLArgs;
};
export type EditorImageInputGetURLMutationResponse = {
    readonly postGetImageUploadURL: {
        readonly url: string;
        readonly filename: string;
        readonly fields: string;
    };
};
export type EditorImageInputGetURLMutation = {
    readonly response: EditorImageInputGetURLMutationResponse;
    readonly variables: EditorImageInputGetURLMutationVariables;
};



/*
mutation EditorImageInputGetURLMutation(
  $args: PostGetImageUploadURLArgs!
) {
  postGetImageUploadURL(args: $args) {
    url
    filename
    fields
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "args"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "args",
        "variableName": "args"
      }
    ],
    "concreteType": "FileUploadURLResult",
    "kind": "LinkedField",
    "name": "postGetImageUploadURL",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "filename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fields",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditorImageInputGetURLMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditorImageInputGetURLMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2fad53f55c9b3e164962769531d9b20e",
    "id": null,
    "metadata": {},
    "name": "EditorImageInputGetURLMutation",
    "operationKind": "mutation",
    "text": "mutation EditorImageInputGetURLMutation(\n  $args: PostGetImageUploadURLArgs!\n) {\n  postGetImageUploadURL(args: $args) {\n    url\n    filename\n    fields\n  }\n}\n"
  }
};
})();
(node as any).hash = '904b7ef32de738daeea7a08cc545181e';
export default node;
