import { API } from '@indieocean/apidef'
import { assert, fGet, FGet } from '@indieocean/utils'
import { Editor, mergeAttributes, Node, NodeConfig } from '@tiptap/core'
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react'
import React from 'react'
import { AppLink } from '../../../../Common/Tools/AppLink'
import { useBooleanStateObj } from '../../../../Utils/UseBooleanStateObj'
import { BookUtils } from '../../../Store/Book/BookUtils'
import { Book, isBookDeleted, isBookLive } from '../../../Store/Book/Home/Book'
import { EditorBookControl } from './EditorBookControl'

export interface EditorBookInlineNodeOptions {
  HTMLAttributes: Record<string, any>
  books: Map<string, Book>
}

export const EditorBookInlineNode = Node.create<EditorBookInlineNodeOptions>({
  name: 'bookInline',
  priority: 100,
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      userId: {default: ''},
      bookId: {default: ''},
      includeAuthor: {default: false},
    }
  },

  parseHTML() {
    return [{tag: 'a[data-inlineBook]'}]
  },

  renderHTML({HTMLAttributes, node}) {
    return [
      'a',
      mergeAttributes(
        {'data-inlineBook': ''},
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(_Component)
  },
})

const _Component = React.memo(
  ({
    node,
    deleteNode,
    extension,
    editor,
    updateAttributes,
  }: {
    node: Parameters<FGet<NodeConfig['renderHTML']>>[0]['node']
    deleteNode: () => void
    editor: Editor
    updateAttributes: (attrs: Record<string, any>) => void
    extension: Node<EditorBookInlineNodeOptions>
  }) => {
    const {books} = extension.options
    const userBookId = API.readUserBookIdFromEditorNodeAttrs(node.attrs)
    const {includeAuthor} = node.attrs
    const htmlAttrs = mergeAttributes(extension.options.HTMLAttributes)
    const className = htmlAttrs.class
    delete htmlAttrs.class

    const userBookIdKey = API.GQLIds.book(userBookId)
    const book = fGet(books.get(userBookIdKey))

    assert(isBookLive(book) || isBookDeleted(book))
    const {title, authors} = book.data.info
    const text = includeAuthor
      ? `${title} by ${BookUtils.Authors.toStr(authors)}`
      : title

    const controlOpen = useBooleanStateObj(false)
    const bookPath = BookUtils.inlinePathIfLive(book)
    const andFocus = (fn: () => void) => () => {
      fn()
      window.setTimeout(() => editor.chain().focus().run(), 0)
    }
    return (
      <NodeViewWrapper className="inline">
        {editor.isEditable ? (
          // The spaces help with android bug where backspace causes duplicate text.
          <>
            {' '}
            <button className={className} onClick={controlOpen.setTrue}>
              {text}
            </button>{' '}
          </>
        ) : (
          <>
            {' '}
            <AppLink path={bookPath} className={className}>
              {text}
            </AppLink>{' '}
          </>
        )}
        {controlOpen.value && (
          <EditorBookControl
            bookPath={bookPath}
            onCancel={andFocus(controlOpen.setFalse)}
            onDelete={deleteNode}
          />
        )}
      </NodeViewWrapper>
    )
  }
)
