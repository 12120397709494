import {useRouter} from 'next/router'
import React, {useState} from 'react'
import {graphql} from 'react-relay'
import {ConfirmAlert} from '../../../../Common/Modal/ConfirmAlert'
import {useGQLMutation} from '../../../../Common/Tools/UseGQLMutation'
import {useGlobalToasts} from '../../../../Common/WithGlobalToasts'
import {BookAdmin, isBookLive} from '../Home/Book'
import {UseDeleteBookMutation} from './__generated__/UseDeleteBookMutation.graphql'

const deleteMutation = graphql`
  mutation UseDeleteBookMutation($args: BookDeleteArgs!, $userId: String!) {
    bookDelete(args: $args) {
      id
      ...Book_book
    }
  }
`

export function useDeleteBook(
  book: BookAdmin,
  backAfterDelete: 'backAfterDelete' | 'noBackAfterDelete'
) {
  const {bookId, userId} = book

  const {infoToast} = useGlobalToasts()
  const [confirming, setConfirming] = useState(false)
  const [confirmingReview, setConfirmingReview] = useState(false)
  const [commit, isRunning] = useGQLMutation<UseDeleteBookMutation>(
    deleteMutation,
    'soft'
  )
  const router = useRouter()
  const handleDelete = () => {
    commit({
      variables: {args: {userId, bookId}, userId},
      onCompleted: () => {
        infoToast('Deleted book.')
        if (backAfterDelete === 'backAfterDelete') router.back()
      },
    })
  }
  const alert = (
    <>
      <ConfirmAlert
        title="Delete Book?"
        confirmText="Delete Book"
        isWarning
        isRunning={isRunning}
        open={confirming}
        onCancel={() => setConfirming(false)}
        onConfirm={() => {
          if (isBookLive(book) && book.data.review?.postData) {
            setConfirming(false)
            window.setTimeout(() => setConfirmingReview(true), 0)
          } else {
            handleDelete()
          }
        }}
      >
        Are you sure you want to delete this book?
      </ConfirmAlert>
      <ConfirmAlert
        title="Delete Review?"
        confirmText="Delete Review"
        isWarning
        isRunning={isRunning}
        open={confirmingReview}
        onCancel={() => setConfirmingReview(false)}
        onConfirm={handleDelete}
      >
        This book has a review. When you delete the book, the review is deleted
        as well. Are you sure you want to proceed?
      </ConfirmAlert>
    </>
  )

  return [() => setConfirming(true), alert] as const
}
