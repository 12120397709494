/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Comment_comment = {
    readonly id: string;
    readonly commentKey: {
        readonly userId: string;
        readonly commentId: string;
    };
    readonly target: {
        readonly __typename: "Book";
        readonly bookKey: {
            readonly userId: string;
            readonly bookId: string;
        };
    } | {
        readonly __typename: "BlogPost";
        readonly postKey: {
            readonly userId: string;
            readonly postId: string;
        };
    } | {
        readonly __typename: "Review";
        readonly postKey: {
            readonly userId: string;
            readonly postId: string;
        };
    } | {
        readonly __typename: "ShortPost";
        readonly postKey: {
            readonly userId: string;
            readonly postId: string;
        };
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    };
    readonly path: ReadonlyArray<number>;
    readonly data: {
        readonly store: {
            readonly id: string;
            readonly user: {
                readonly id: string;
                readonly userId: string;
            };
            readonly name: string;
            readonly slug: string;
            readonly data: {
                readonly profileImage: string | null;
                readonly introduction: string | null;
                readonly links: {
                    readonly twitter: {
                        readonly username: string;
                    } | null;
                };
                readonly amazonAssociateIds: ReadonlyArray<{
                    readonly country: API$CountryCode;
                    readonly associateId: string;
                }>;
                readonly follows: {
                    readonly id: string;
                    readonly aggregate: {
                        readonly id: string;
                        readonly followsWhereIsSrcCount: number;
                        readonly followsWhereIsTargetCount: number;
                    };
                    readonly relativeToOther: {
                        readonly isOtherFollowsSrc: boolean;
                        readonly isOtherFollowsTarget: boolean;
                        readonly selfFollowTargetsInOtherFollowTargets: number;
                        readonly selfFollowSrcsInOtherFollowTargets: number;
                    } | null;
                };
            } | null;
        };
        readonly createdTime: number;
        readonly lastEditedTime: number;
        readonly content: string;
        readonly books: ReadonlyArray<{
            readonly __typename: string;
            readonly id: string;
            readonly userId: string;
            readonly bookId: string;
            readonly bookKey: {
                readonly bookId: string;
                readonly userId: string;
            };
            readonly timeAdded: number;
            readonly store: {
                readonly id: string;
                readonly slug: string;
                readonly name: string;
                readonly data: {
                    readonly __typename: string;
                    readonly links: {
                        readonly twitter: {
                            readonly username: string;
                        } | null;
                    };
                } | null;
            };
            readonly rating: API$BookRating;
            readonly description: string;
            readonly private: {
                readonly identification: {
                    readonly titleAndAuthor: string;
                };
            } | null;
            readonly data: {
                readonly __typename: "BookDataPending";
                readonly sectionId: string;
            } | {
                readonly __typename: "BookDataRejected";
                readonly timeWhenRejected: number;
                readonly sectionId: string;
                readonly reason: string;
            } | {
                readonly __typename: "BookDataDeleted";
                readonly info: {
                    readonly slug: string;
                    readonly cover: {
                        readonly url: string;
                        readonly aspectRatio: number;
                    };
                    readonly title: string;
                    readonly subtitle: string;
                    readonly authors: ReadonlyArray<string>;
                };
            } | {
                readonly __typename: "BookDataLive";
                readonly sectionId: string;
                readonly info: {
                    readonly slug: string;
                    readonly title: string;
                    readonly subtitle: string;
                    readonly authors: ReadonlyArray<string>;
                    readonly cover: {
                        readonly url: string;
                        readonly aspectRatio: number;
                    };
                    readonly amazonURL: string;
                    readonly purchasingDetails: {
                        readonly variants: {
                            readonly paperback: {
                                readonly price: number;
                            } | null;
                            readonly hardcover: {
                                readonly price: number;
                            } | null;
                        };
                        readonly shippingCost: {
                            readonly freePriority: number;
                        };
                    } | null;
                };
                readonly likes: {
                    readonly id: string;
                    readonly aggregate: {
                        readonly id: string;
                        readonly likeCount: number;
                    };
                    readonly relativeToStore: {
                        readonly isLiked: boolean;
                    } | null;
                };
                readonly comments: {
                    readonly id: string;
                    readonly count: number;
                };
                readonly review: {
                    readonly id: string;
                    readonly postId: string;
                    readonly createdTime: number;
                    readonly postData: {
                        readonly lastEditedTime: number;
                        readonly stage: {
                            readonly __typename: "ReviewStagePublished";
                            readonly publishedTime: number;
                        } | {
                            /*This will never be '%other', but we need some
                            value in case none of the concrete values match.*/
                            readonly __typename: "%other";
                        };
                        readonly snippet: string;
                    } | null;
                } | null;
            } | {
                /*This will never be '%other', but we need some
                value in case none of the concrete values match.*/
                readonly __typename: "%other";
            };
        }>;
        readonly stores: ReadonlyArray<{
            readonly id: string;
            readonly user: {
                readonly id: string;
                readonly userId: string;
            };
            readonly name: string;
            readonly slug: string;
            readonly data: {
                readonly profileImage: string | null;
                readonly introduction: string | null;
                readonly links: {
                    readonly twitter: {
                        readonly username: string;
                    } | null;
                };
                readonly amazonAssociateIds: ReadonlyArray<{
                    readonly country: API$CountryCode;
                    readonly associateId: string;
                }>;
                readonly follows: {
                    readonly id: string;
                    readonly aggregate: {
                        readonly id: string;
                        readonly followsWhereIsSrcCount: number;
                        readonly followsWhereIsTargetCount: number;
                    };
                    readonly relativeToOther: {
                        readonly isOtherFollowsSrc: boolean;
                        readonly isOtherFollowsTarget: boolean;
                        readonly selfFollowTargetsInOtherFollowTargets: number;
                        readonly selfFollowSrcsInOtherFollowTargets: number;
                    } | null;
                };
            } | null;
        }>;
        readonly likes: {
            readonly id: string;
            readonly aggregate: {
                readonly id: string;
                readonly likeCount: number;
            };
            readonly relativeToStore: {
                readonly isLiked: boolean;
            } | null;
        };
    } | null;
    readonly " $refType": "Comment_comment";
};
export type Comment_comment$data = Comment_comment;
export type Comment_comment$key = {
    readonly " $data"?: Comment_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"Comment_comment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bookId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postId",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PostKey",
    "kind": "LinkedField",
    "name": "postKey",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v4/*: any*/)
    ],
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "StoreLinks",
  "kind": "LinkedField",
  "name": "links",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreLinksTwitter",
      "kind": "LinkedField",
      "name": "twitter",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v10 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "storageKey": null
  },
  (v6/*: any*/),
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "StoreData",
    "kind": "LinkedField",
    "name": "data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profileImage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "introduction",
        "storageKey": null
      },
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "AmazonAssociateId",
        "kind": "LinkedField",
        "name": "amazonAssociateIds",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "country",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "associateId",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "StoreFollows",
        "kind": "LinkedField",
        "name": "follows",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreFollowsAggregate",
            "kind": "LinkedField",
            "name": "aggregate",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "followsWhereIsSrcCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "followsWhereIsTargetCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "StoreFollowsRelativeToOther",
            "kind": "LinkedField",
            "name": "relativeToOther",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOtherFollowsSrc",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOtherFollowsTarget",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "selfFollowTargetsInOtherFollowTargets",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "selfFollowSrcsInOtherFollowTargets",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdTime",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastEditedTime",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "BookCover",
  "kind": "LinkedField",
  "name": "cover",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aspectRatio",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authors",
  "storageKey": null
},
v18 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "price",
    "storageKey": null
  }
],
v19 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "likeCount",
    "storageKey": null
  }
],
v20 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isLiked",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Comment_comment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CommentKey",
      "kind": "LinkedField",
      "name": "commentKey",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commentId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "target",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BookKey",
              "kind": "LinkedField",
              "name": "bookKey",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "Book",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "BlogPost",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "Review",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "ShortPost",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CommentData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Store",
          "kind": "LinkedField",
          "name": "store",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": null
        },
        (v11/*: any*/),
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Book",
          "kind": "LinkedField",
          "name": "books",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v0/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "BookKey",
              "kind": "LinkedField",
              "name": "bookKey",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timeAdded",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Store",
              "kind": "LinkedField",
              "name": "store",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v7/*: any*/),
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StoreData",
                  "kind": "LinkedField",
                  "name": "data",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v8/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rating",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Book_Private",
              "kind": "LinkedField",
              "name": "private",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BookIdentification",
                  "kind": "LinkedField",
                  "name": "identification",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "titleAndAuthor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "data",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v13/*: any*/)
                  ],
                  "type": "BookDataPending",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "timeWhenRejected",
                      "storageKey": null
                    },
                    (v13/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "reason",
                      "storageKey": null
                    }
                  ],
                  "type": "BookDataRejected",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CatalogBook",
                      "kind": "LinkedField",
                      "name": "info",
                      "plural": false,
                      "selections": [
                        (v7/*: any*/),
                        (v14/*: any*/),
                        (v15/*: any*/),
                        (v16/*: any*/),
                        (v17/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "BookDataDeleted",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v13/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CatalogBook",
                      "kind": "LinkedField",
                      "name": "info",
                      "plural": false,
                      "selections": [
                        (v7/*: any*/),
                        (v15/*: any*/),
                        (v16/*: any*/),
                        (v17/*: any*/),
                        (v14/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "amazonURL",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BookPurchasingDetails",
                          "kind": "LinkedField",
                          "name": "purchasingDetails",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "BookVariants",
                              "kind": "LinkedField",
                              "name": "variants",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "BookVariant",
                                  "kind": "LinkedField",
                                  "name": "paperback",
                                  "plural": false,
                                  "selections": (v18/*: any*/),
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "BookVariant",
                                  "kind": "LinkedField",
                                  "name": "hardcover",
                                  "plural": false,
                                  "selections": (v18/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "BookShippingCost",
                              "kind": "LinkedField",
                              "name": "shippingCost",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "freePriority",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BookLikes",
                      "kind": "LinkedField",
                      "name": "likes",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BookLikesAggregate",
                          "kind": "LinkedField",
                          "name": "aggregate",
                          "plural": false,
                          "selections": (v19/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": (v9/*: any*/),
                          "concreteType": "BookLikesRelativeToStore",
                          "kind": "LinkedField",
                          "name": "relativeToStore",
                          "plural": false,
                          "selections": (v20/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Comments",
                      "kind": "LinkedField",
                      "name": "comments",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "count",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Review",
                      "kind": "LinkedField",
                      "name": "review",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v4/*: any*/),
                        (v11/*: any*/),
                        {
                          "alias": "postData",
                          "args": null,
                          "concreteType": "ReviewData",
                          "kind": "LinkedField",
                          "name": "data",
                          "plural": false,
                          "selections": [
                            (v12/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "stage",
                              "plural": false,
                              "selections": [
                                (v2/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "publishedTime",
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "ReviewStagePublished",
                                  "abstractKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "snippet",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "BookDataLive",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Store",
          "kind": "LinkedField",
          "name": "stores",
          "plural": true,
          "selections": (v10/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CommentLikes",
          "kind": "LinkedField",
          "name": "likes",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CommentLikesAggregate",
              "kind": "LinkedField",
              "name": "aggregate",
              "plural": false,
              "selections": (v19/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v9/*: any*/),
              "concreteType": "CommentLikesRelativeToStore",
              "kind": "LinkedField",
              "name": "relativeToStore",
              "plural": false,
              "selections": (v20/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
})();
(node as any).hash = '9d29b5b8ab86935fa302eba965e06a06';
export default node;
