import {API} from '@indieocean/apidef'
import React from 'react'
import {AppLink} from '../../../../Common/Tools/AppLink'
import {PostSnippet} from '../../../Post/Common/PostSnippet'
import {BookUtils} from '../BookUtils'
import {BookVisibleInAdmin, isBookLive} from '../Home/Book'

export const BookReviewSnippet = React.memo(
  ({
    book,
    pathIfQuickReview,
    className = '',
    background,
  }: {
    className?: string
    book: BookVisibleInAdmin
    pathIfQuickReview: API.Path | null
    background: 'page' | 'extraLightSection'
  }) => {
    if (
      isBookLive(book) &&
      book.data.review?.postData?.stage.__typename === 'ReviewStagePublished'
    ) {
      return (
        <AppLink path={BookUtils.path(book).review.root()} className={className}>
          <PostSnippet {...{background}}>
            {book.data.review.postData.snippet}
          </PostSnippet>
        </AppLink>
      )
    }

    if (book.description) {
      return (
        <AppLink
          path={pathIfQuickReview}
          className={` ${className} block font-karla`}
        >
          {book.description}
        </AppLink>
      )
    }
    return <></>
  }
)
