import { API } from '@indieocean/apidef'
import { useBasicStoreLive } from '../Components/Common/Page/WithBasicStoreLive'
import { useLocalizationCountry } from '../Components/Common/WithUser'
import { BookLive } from '../Components/Pages/Store/Book/Home/Book'

export function useBuyFromAmazonURL(book: BookLive) {
  const store = useBasicStoreLive()
  const localeCountry = useLocalizationCountry() ?? 'US'

  const buyOnIndieOcean = book.data.info.purchasingDetails && localeCountry === 'US'
  const target = store.data.amazonAssociateIds.find(
    x => x.country === localeCountry
  )
  let url: URL
  if (target) {
    url = new URL(book.data.info.amazonURL + '/ref=nosim')
    url.searchParams.set('tag', target.associateId)
  } else {
    url = new URL(book.data.info.amazonURL)
  }

  url.host = API.getAmazonCountryDomain(localeCountry, 'US')
  return {amazonURL: url, buyOnIndieOcean}
}
