import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {
  faAt,
  faBold,
  faBookOpen,
  faCaretDown,
  faImage,
  faItalic,
  faLink,
  faQuoteLeft,
  faText,
} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Dialog} from '@headlessui/react'
import {assert} from '@indieocean/utils'
import React from 'react'
import {
  BooleanStateObj,
  useBooleanStateObj,
} from '../../../../Utils/UseBooleanStateObj'
import {EditorRenderProps} from '../Editor'

export const CompactEditorMenuOnNarrow = React.memo(
  (props: EditorRenderProps) => {
    const open = useBooleanStateObj(false)
    return (
      <>
        <button
          className={`vertNav:hidden w-[70px] py-1 rounded-tl-sm rounded-tr-sm sectionDark sticky top-[4.31rem] z-10`}
          onClick={open.setTrue}
        >
          <FontAwesomeIcon icon={faText} />
          <FontAwesomeIcon className="ml-1" icon={faCaretDown} />
        </button>

        <_Modal {...props} open={open} />
      </>
    )
  }
)

const _Modal = React.memo(
  ({
    editor,
    callbacks: cb,
    open,
    type,
  }: EditorRenderProps & {open: BooleanStateObj}) => {
    assert(type === 'compact' || type === 'minimal')
    const Btn = ({
      icon,
      onClick,
      text,
      active = false,
    }: {
      icon: IconProp
      onClick: () => void
      text: string
      active?: boolean
    }) => (
      <button
        className={`text-left py-2 `}
        onClick={() => (onClick(), open.setFalse())}
      >
        <span
          className={`${
            active ? 'btn-dark' : ''
          } py-1 px-4 inline-grid items-center`}
          style={{grid: 'auto /35px auto'}}
        >
          <FontAwesomeIcon className="mr-2" icon={icon} />
          {text}
        </span>
      </button>
    )

    return (
      <Dialog
        open={open.value}
        onClose={open.setFalse}
        className="fixed z-50 inset-0 overflow-y-auto flex flex-col justify-end"
      >
        <Dialog.Overlay className="modal-overlay" />
        <div className=" bg-pageBG text-pageFG z-10 rounded-t-lg  p-2 shadow-xl">
          <div className="grid text-lg  my-2">
            <Btn
              icon={faBold}
              onClick={cb.onBold}
              text="Bold"
              active={editor.isActive('bold')}
            />
            <Btn
              icon={faItalic}
              onClick={cb.onItalic}
              text="Italic"
              active={editor.isActive('italic')}
            />
            <Btn icon={faBookOpen} onClick={cb.onBook} text="Book" />
            <Btn icon={faAt} onClick={cb.onStore} text="Mention" />
            <Btn icon={faLink} onClick={cb.onLink} text="Link" />
            {type === 'compact' && (
              <Btn icon={faImage} onClick={cb.onImage} text="Image" />
            )}
            <Btn
              icon={faQuoteLeft}
              onClick={cb.onBlockQuote}
              active={editor.isActive('blockquote')}
              text="Quote"
            />
          </div>
        </div>
      </Dialog>
    )
  }
)
