import {API} from '@indieocean/apidef'
import {string} from '@mojotech/json-type-validation'
import React from 'react'
import {TextInput} from '../../../../Common/Form/TextInput'
import {ModalBase} from '../../../../Common/Modal/ModalBase'
import {useInputControl} from '../../../../Utils/UseInputControl'

const urlDecoder = API.Decode.url('http:', 'https:')
export const EditorLinkInput = React.memo(
  ({
    text,
    url,
    onCancel,
    onLink,
  }: {
    text: string
    url?: string
    onCancel: () => void
    onLink: (text: string, href: string) => void
  }) => {
    const controls = {
      text: useInputControl(text, string()),
      url: useInputControl(
        url && urlDecoder.run(url).ok
          ? url
          : urlDecoder.run(text).ok
          ? text
          : 'https://',
        urlDecoder
      ),
    }
    const handleDone = () => {
      if (controls.text.validation.ok && controls.url.validation.ok) {
        onLink(controls.text.validation.result, controls.url.validation.result)
      } else {
        controls.text.setValidating(true)
        controls.url.setValidating(true)
      }
    }
    return (
      <ModalBase>
        <div
          className="grid items-end gap-x-2 gap-y-2"
          style={{grid: 'auto auto/ auto 1fr'}}
        >
          <h2 className="font-bold">Text</h2>
          <TextInput className="" control={controls.text} />

          <h2 className="font-bold">URL</h2>
          <TextInput className="" control={controls.url} onNext={handleDone} />
        </div>
        <div className="flex flex-row justify-end items-start mt-6">
          <button className="underline btn-md" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn-md btn-dark" onClick={handleDone}>
            Done
          </button>
        </div>
      </ModalBase>
    )
  }
)
