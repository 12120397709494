import { API } from '@indieocean/apidef'
import { AsyncReturnType, fGet } from '@indieocean/utils'
import { SearchIndex } from 'algoliasearch/lite'
import { useEffect, useState } from 'react'
import { useAlgoliaIndex } from '../../../Common/WithAlgoliaIndex'
import { asyncEffect } from '../../../Utils/AsyncEffect'
import { processAlgoliaHighlight } from './ProcessAlgoliaHighlight'

export type SearchStoresHit = ReturnType<typeof _processHit>
export function useSearchStores(term: string) {
  const index = useAlgoliaIndex().stores
  const [result, setResult] = useState<SearchStoresHit[]>([])
  useEffect(() => {
    return asyncEffect(async status => {
      const result = await _searchIndex(index, term)
      if (status.canceled) return
      setResult(result.hits.map(_processHit))
    })
  }, [index, term])
  return result
}

const _searchIndex = (index: SearchIndex, term: string) =>
  index.search<API.SearchDataStore>(term, {
    hitsPerPage: 5,
    queryType: 'prefixAll',
    restrictSearchableAttributes: ['slug', 'name'],
    minWordSizefor1Typo: 3,
    minWordSizefor2Typos: 6,
  })

function _processHit(hit: AsyncReturnType<typeof _searchIndex>['hits'][0]) {
  return {
    store: hit,
    highlighted: {
      slug: processAlgoliaHighlight(fGet(hit._highlightResult?.slug)),
      name: processAlgoliaHighlight(fGet(hit._highlightResult?.name)),
    },
  }
}
