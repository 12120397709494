import { assert, FGet, Replace } from '@indieocean/utils'
import { formatDistanceToNow } from 'date-fns'
import React, { useState } from 'react'
import { graphql } from 'relay-runtime'
import { GQL } from '../../../../Generated/GQL/TypesFromSDL'
import { isBasicStoreLive, storePath } from '../../../Common/Page/WithBasicStoreLive'
import { ProfileImage } from '../../../Common/ProfileImage'
import { PublishedPost } from '../../Post/Post'
import { BookLive } from '../../Store/Book/Home/Book'
import { CompactEditorDisplay } from '../Editor/CompactEditorDisplay/CompactEditorDisplay'
import { Editor } from '../Editor/Editor'
import { AddComment } from './AddComment'
import { CommentActionBar } from './CommentActionBar'

graphql`
  fragment Comment_comment on Comment {
    id
    commentKey {
      userId
      commentId
    }
    target {
      __typename
      ... on Book {
        bookKey {
          userId
          bookId
        }
      }
      ... on BlogPost {
        postKey {
          userId
          postId
        }
      }
      ... on Review {
        postKey {
          userId
          postId
        }
      }
      ... on ShortPost {
        postKey {
          userId
          postId
        }
      }
    }

    path
    data {
      store {
        ...WithBasicStoreLive_store @relay(mask: false)
      }
      createdTime
      lastEditedTime
      content
      books {
        ...Book_book @relay(mask: false)
      }
      stores {
        ...WithBasicStoreLive_store @relay(mask: false)
      }
      likes {
        id
        aggregate {
          id
          likeCount
        }
        relativeToStore(userId: $userId) {
          isLiked
        }
      }
    }
  }
`

export type Comment = GQL.Comment_Comment
export type NotDeletedComment = Replace<Comment, 'data', FGet<Comment['data']>>
export const isNotDeletedComment = (x: Comment): x is NotDeletedComment =>
  x.data !== null
export type DeletedComment = Replace<Comment, 'data', null>

export const isDeletedComment = (x: Comment): x is DeletedComment =>
  x.data === null

export const commentPath = (x: NotDeletedComment) =>
  storePath(x.data.store).comment(x.commentKey.commentId)

export const Comment = React.memo(
  ({
    comment,
    className = '',
    target,
  }: {
    target: PublishedPost | BookLive
    comment: Comment
    className?: string
  }) => {
    const [data] = useState(comment.data)

    const [isReplyOpen, setIsReplyOpen] = useState(false)

    if (!data || !isNotDeletedComment(comment)) {
      return (
        <div className={`${className}`}>
          <h2 className="">Deleted</h2>
        </div>
      )
    }
    const {store} = data
    assert(isBasicStoreLive(store))

    return (
      <Editor
        userIdOfPostAuthor={comment.commentKey.userId}
        data={data}
        editable={false}
        type="minimal"
        isExternalSaved
      >
        {props => (
          <div
            className={`${className}`}
            style={{paddingLeft: `${30 * (comment.path.length - 2)}px`}}
          >
            <div className="flex gap-x-2 items-center">
              <ProfileImage store={store} size={30} />
              <div className="">
                <h2 className="text-sm">{store.name}</h2>
                <h2 className="text-xs lighten-2">
                  {formatDistanceToNow(data.createdTime * 1000)} ago
                </h2>
              </div>
            </div>
            <CompactEditorDisplay className={``} propsFromEditor={props} />
            <CommentActionBar
              onReply={() => setIsReplyOpen(true)}
              comment={comment}
            />

            {isReplyOpen && (
              <AddComment
                className="pl-[30px] mt-3"
                target={target}
                parentPath={comment.path}
                onDone={() => setIsReplyOpen(false)}
              />
            )}
          </div>
        )}
      </Editor>
    )
  }
)
