import React, {CSSProperties, ReactNode} from 'react'
import {CenteredCircleProgress} from './CenteredCircleProgress'

export const CircleProgressIfRunning = React.memo(
  ({
    className = '',
    isRunning,
    children,
    style,
    inner,
  }: {
    className?: string
    isRunning: boolean
    children: ReactNode
    style?: CSSProperties
    inner?: {
      className?: string
      style?: CSSProperties
    }
  }) => {
    return (
      <div className={`${className} relative`} style={style}>
        <div
          className={`${inner?.className ?? ''} 
          ${isRunning ? 'overlayed' : ''}`}
          style={inner?.style}
        >
          {children}
        </div>
        {isRunning && <CenteredCircleProgress />}
      </div>
    )
  }
)
