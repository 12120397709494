import {fGet} from '@indieocean/utils'
import React from 'react'
import {useBuyFromAmazonURL} from '../../../../../Utils/UseBuyFromAmazonURL'
import {useBasicStoreLive} from '../../../../Common/Page/WithBasicStoreLive'
import {AppLink} from '../../../../Common/Tools/AppLink'
import {BookUtils} from '../BookUtils'
import {BookLive} from './Book'

export const BookBuyBlock = React.memo(
  ({book, className=''}: {book: BookLive; className?: string}) => {
    const {info} = book.data
    const basicStore = useBasicStoreLive()

    const {amazonURL, buyOnIndieOcean} = useBuyFromAmazonURL(book)
    return (
      <div className={`${className}`}>
        {buyOnIndieOcean && (
          <>
            <div className="flex flex-row justify-start items-end">
              <AppLink
                path={BookUtils.path(book).buy}
                className="a-btn btn-dark btn-md sm:btn-sm mr-3 whitespace-nowrap "
              >
                Buy Here
              </AppLink>
              <div
                className="text-base-tight sm:text-sm-tight  lighten "
                style={{
                  grid: `"pride shipping" auto  
      "price1 label1" auto
                   "price2 label2" auto 
                   / auto 1fr`,
                }}
              >
                <h2 className="">
                  From{' '}
                  {BookUtils.Price.formatLower(fGet(info.purchasingDetails))}
                </h2>
                <h2 className="text-sm " style={{gridArea: 'shipping'}}>
                  Free priority shipping
                </h2>
              </div>
            </div>
          </>
        )}
        <div className="">
          <a
            className="a-btn btn-dark btn-md sm:btn-sm whitespace-nowrap mt-3"
            href={amazonURL.toString()}
            onClick={() => {
              ;(window as any).gtag('event', 'click', {
                event_category: 'outbound',
                event_label: `${amazonURL.host}-${basicStore.slug}/${info.slug}`,
                transport_type: 'beacon',
              })
            }}
          >
            Buy From Amazon
          </a>
        </div>
      </div>
    )
  }
)
