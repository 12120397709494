/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CommentLikeArgs = {
    targetKey: CommentKeyIn;
    userId: string;
};
export type CommentKeyIn = {
    commentId: string;
    userId: string;
};
export type CommentToggleLikeUndoMutationVariables = {
    args: CommentLikeArgs;
    userId: string;
};
export type CommentToggleLikeUndoMutationResponse = {
    readonly commentUndoLike: {
        readonly id: string;
        readonly aggregate: {
            readonly id: string;
            readonly likeCount: number;
        };
        readonly relativeToStore: {
            readonly isLiked: boolean;
        } | null;
    };
};
export type CommentToggleLikeUndoMutation = {
    readonly response: CommentToggleLikeUndoMutationResponse;
    readonly variables: CommentToggleLikeUndoMutationVariables;
};



/*
mutation CommentToggleLikeUndoMutation(
  $args: CommentLikeArgs!
  $userId: String!
) {
  commentUndoLike(args: $args) {
    id
    aggregate {
      id
      likeCount
    }
    relativeToStore(userId: $userId) {
      isLiked
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "args"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "args",
        "variableName": "args"
      }
    ],
    "concreteType": "CommentLikes",
    "kind": "LinkedField",
    "name": "commentUndoLike",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CommentLikesAggregate",
        "kind": "LinkedField",
        "name": "aggregate",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "likeCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "userId",
            "variableName": "userId"
          }
        ],
        "concreteType": "CommentLikesRelativeToStore",
        "kind": "LinkedField",
        "name": "relativeToStore",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLiked",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentToggleLikeUndoMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommentToggleLikeUndoMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e5009b5d90d329c515eeff1b6f83542d",
    "id": null,
    "metadata": {},
    "name": "CommentToggleLikeUndoMutation",
    "operationKind": "mutation",
    "text": "mutation CommentToggleLikeUndoMutation(\n  $args: CommentLikeArgs!\n  $userId: String!\n) {\n  commentUndoLike(args: $args) {\n    id\n    aggregate {\n      id\n      likeCount\n    }\n    relativeToStore(userId: $userId) {\n      isLiked\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '115447002f9bcbe6b221ef1615e3246c';
export default node;
