import { Dialog } from '@headlessui/react'
import { API } from '@indieocean/apidef'
import { noCase } from '@indieocean/utils'
import React, { useState } from 'react'
import { BookSearch } from '../../BookSearch'
import { EditorBookSettings } from './EditorBookSettings'

export const EditorBookInput = React.memo(
  ({
    onCancel,
    onBook,
    inlineOnly,
  }: {
    onCancel: () => void
    onBook: (
      book: API.SearchDataUserBook,
      type: 'title' | 'titleAndAuthor' | 'float' | 'block'
    ) => void
    inlineOnly: boolean
  }) => {
    const [state, setState] = useState<
      {type: 'search'} | {type: 'chooseType'; book: API.SearchDataUserBook}
    >({type: 'search'})
    return state.type === 'search' ? (
      <Dialog
        open
        onClose={onCancel}
        className="fixed z-50 inset-0 overflow-y-auto flex flex-col justify-start items-center "
      >
        <Dialog.Overlay className="modal-overlay" />
        <div
          className="bg-pageBG text-pageFG z-10 rounded-lg w-[75%]
     py-1 px-2 shadow-xl mt-20 sm:mt-20"
          style={{minWidth: 'min(calc(100vw - 20px), 500px)'}}
        >
          <BookSearch
            onCancel={onCancel}
            onBook={book => setState({type: 'chooseType', book})}
          />
        </div>
      </Dialog>
    ) : state.type === 'chooseType' ? (
      <EditorBookSettings
        onCancel={onCancel}
        onDone={type => onBook(state.book, type)}
        inlineOnly={inlineOnly}
      />
    ) : (
      noCase(state)
    )
  }
)
