import {useCallback, useEffect} from 'react'
import {useURLParam} from '../../../Utils/UseURLParam'

export function useScrollTo() {
  const scrollTo = useURLParam('scrollTo')
  const handleScrollTo = useCallback(
    (id: string, behavior: 'smooth' | 'jump') => {
      const element = document.getElementById(id)
      if (!element) return
      window.setTimeout(
        () =>
          element.scrollIntoView({
            block: 'start',
            inline: 'start',
            behavior: behavior === 'jump' ? 'auto' : behavior,
          }),
        0
      )
    },
    []
  )
  useEffect(() => {
    if (!scrollTo) return
    handleScrollTo(scrollTo, 'jump')
  }, [scrollTo, handleScrollTo])

  return (id: string) => handleScrollTo(id, 'smooth')
}
