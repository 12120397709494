import {assert, fGet} from '@indieocean/utils'
import {graphql} from 'relay-runtime'
import {useCreateStoreOrAction} from '../../../Common/Tools/UseCreateStoreOrAction'
import {useGQLMutation} from '../../../Common/Tools/UseGQLMutation'
import {useGlobalToasts} from '../../../Common/WithGlobalToasts'
import {Book, isBookLive} from './Home/Book'
import {
  UseBookToggleLikeMutation,
  UseBookToggleLikeMutationResponse,
} from './__generated__/UseBookToggleLikeMutation.graphql'
import {
  UseBookToggleLikeUndoMutation,
  UseBookToggleLikeUndoMutationResponse,
} from './__generated__/UseBookToggleLikeUndoMutation.graphql'

const likeMutation = graphql`
  mutation UseBookToggleLikeMutation($args: BookLikeArgs!, $userId: String!) {
    bookLike(args: $args) {
      id
      aggregate {
        id
        likeCount
      }
      relativeToStore(userId: $userId) {
        isLiked
      }
    }
  }
`

const undoLikeMutation = graphql`
  mutation UseBookToggleLikeUndoMutation(
    $args: BookLikeArgs!
    $userId: String!
  ) {
    bookUndoLike(args: $args) {
      id
      aggregate {
        id
        likeCount
      }
      relativeToStore(userId: $userId) {
        isLiked
      }
    }
  }
`

export function useBookToggleLike(book: Book) {
  const [commitLike] = useGQLMutation<UseBookToggleLikeMutation>(
    likeMutation,
    'soft'
  )
  const [commitUndoLike] = useGQLMutation<UseBookToggleLikeUndoMutation>(
    undoLikeMutation,
    'soft'
  )

  const {infoToast} = useGlobalToasts()

  const handleToggleLike = useCreateStoreOrAction(user => {
    if (user.userId === book.userId) {
      infoToast("That's your own book!")
      return
    }
    assert(isBookLive(book))
    const relativeToStore = fGet(book.data.likes.relativeToStore)
    const isLiked = relativeToStore.isLiked
    const targetKey = book.bookKey
    const variables = {
      args: {userId: user.userId, targetKey},
      userId: user.userId,
    }

    const likeResponse: UseBookToggleLikeMutationResponse = {
      bookLike: {
        id: book.data.likes.id,
        aggregate: {
          id: book.data.likes.aggregate.id,
          likeCount: book.data.likes.aggregate.likeCount + 1,
        },
        relativeToStore: {
          isLiked: true,
        },
      },
    }

    const undoLikeResponse: UseBookToggleLikeUndoMutationResponse = {
      bookUndoLike: {
        id: book.data.likes.id,
        aggregate: {
          id: book.data.likes.aggregate.id,
          likeCount: book.data.likes.aggregate.likeCount - 1,
        },
        relativeToStore: {
          isLiked: false,
        },
      },
    }

    isLiked
      ? commitUndoLike({
          variables,
          optimisticResponse: undoLikeResponse,
          onCompleted: () => {},
        })
      : commitLike({
          variables,
          optimisticResponse: likeResponse,
          onCompleted: () => {},
        })
  })

  return handleToggleLike
}
