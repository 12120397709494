import { fGet } from '@indieocean/utils'
import React, { useEffect, useRef } from 'react'
import { CenteredCircleProgress } from './CenteredCircleProgress'

export const CenteredCircleLoadTrigger = React.memo(
  ({onTrigger}: {onTrigger: () => void}) => {
    const ref = useRef<HTMLElement>(null)
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) onTrigger()
      })
      observer.observe(fGet(ref.current))
      return () => observer.disconnect()
    }, [onTrigger])

    return <CenteredCircleProgress ref={ref} />
  }
)
