import { faFileAlt } from '@fortawesome/pro-light-svg-icons'
import { faLongArrowAltRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const PostSnippet = React.memo(
  ({
    className = '',
    children,
    background,
  }: {
    className?: string
    children: string
    background: 'page' | 'extraLightSection'
  }) => {
    return (
      <div
        className={` ${className} overflow-hidden max-h-[4.5em] font-karla relative`}
        style={{lineHeight: '1.5em'}}
      >
        {children}
        <h2
          className={`px-3 absolute bottom-0 right-0 ${
            background === 'page' ? 'bg-pageBG' : 'bg-gray-100'
          }`}
          style={{lineHeight: '1.25em'}}
        >
          <FontAwesomeIcon className="lighten mr-1" icon={faFileAlt} />
          <FontAwesomeIcon className="lighten" icon={faLongArrowAltRight} />
        </h2>
      </div>
    )
  }
)
