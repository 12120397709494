import { faMinus, faStar, IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { noCase } from '@indieocean/utils'
import React from 'react'
import { BookLive } from './Book/Home/Book'

export const BookRating = React.memo(
  ({
    rating,
    className,
  }: {
    rating: BookLive['rating']
    className?: string
  }) => {
    if (rating == 0) return <></>
    const result = (icon: IconDefinition, text: string) => (
      <div className={`${className ?? ''}`}>
        <FontAwesomeIcon icon={icon} />
        <span className="ml-2 lighten-2">{text}</span>
      </div>
    )

    return rating === -1
      ? result(faMinus, 'not recommended')
      : rating === 1
      ? result(faStar, 'highly recommended')
      : noCase(rating)
  }
)
