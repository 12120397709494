import {API} from '@indieocean/apidef'
import {assert, noCase} from '@indieocean/utils'
import {mergeAttributes, Node} from '@tiptap/core'
import {Editor, NodeViewWrapper, ReactNodeViewRenderer} from '@tiptap/react'
import React from 'react'
import {Cover} from '../../../../Common/Cover'
import {AppLink} from '../../../../Common/Tools/AppLink'
import {useBooleanStateObj} from '../../../../Utils/UseBooleanStateObj'
import {BookUtils} from '../../../Store/Book/BookUtils'
import {BookActionBar} from '../../../Store/Book/Common/BookActionBar'
import {Book, isBookDeleted, isBookLive} from '../../../Store/Book/Home/Book'
import {EditorBookControl} from './EditorBookControl'

export interface EditorBookBlockNodeOptions {
  HTMLAttributes: Record<string, any>
  books: Map<string, Book>
}

export const EditorBookBlockNode = Node.create<EditorBookBlockNodeOptions>({
  name: 'bookBlock',
  priority: 100,
  group: 'block',
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      userId: {default: ''},
      bookId: {default: ''},
    }
  },

  parseHTML() {
    return [{tag: 'div[data-bookBlock]'}]
  },
  renderHTML({HTMLAttributes, node}) {
    return [
      'div',
      mergeAttributes(
        {'data-bookBlock': ''},
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(_Component)
  },
})

const _Component = React.memo(
  ({
    node,
    extension,
    editor,
    deleteNode,
  }: {
    node: any
    extension: Node<EditorBookBlockNodeOptions>
    editor: Editor
    deleteNode: () => void
  }) => {
    const {books} = extension.options
    const userBookId = API.readUserBookIdFromEditorNodeAttrs(node.attrs)
    const userBookIdKey = API.GQLIds.book(userBookId)
    const book = books.get(userBookIdKey)
    assert(book)
    assert(isBookLive(book) || isBookDeleted(book))
    const controlOpen = useBooleanStateObj(false)
    const andFocus = (fn: () => void) => () => {
      fn()
      window.setTimeout(() => editor.chain().focus().run(), 0)
    }

    const price = (() => {
      switch (book.data.__typename) {
        case 'BookDataDeleted':
          return null
        case 'BookDataLive':
          return book.data.info.purchasingDetails
            ? BookUtils.Price.lower(book.data.info.purchasingDetails)
            : null
        default:
          noCase(book.data)
      }
    })()

    const info = book.data.info

    return (
      <NodeViewWrapper
        className={`flex flex-col items-center my-5 ${
          editor.isEditable ? 'cursor-pointer' : ''
        }`}
        onClick={editor.isEditable ? controlOpen.setTrue : undefined}
      >
        <div
          className="grid max-w-[500px] p-4 gap-x-5"
          style={{
            grid: 'auto  / auto 1fr',
          }}
        >
          <Cover
            cover={book.data.info.cover}
            size="w-[100px]"
            href={
              editor.isEditable ? undefined : BookUtils.path(book).root()
            }
          />
          <div className="">
            <AppLink
              path={editor.isEditable ? null : BookUtils.path(book).root()}
            >
              <h1 className="">{info.title}</h1>
              {info.subtitle.length > 0 && (
                <h2 className=" ">{info.subtitle}</h2>
              )}
              <h2 className="text-sm">
                {BookUtils.Authors.toStr(info.authors)}
              </h2>
            </AppLink>
            <BookActionBar
              book={book}
              className="my-2"
              backAfterDelete={false}
              showBuy={false}
              onScrollToComments={null}
            />
            <AppLink
              path={editor.isEditable ? null : BookUtils.inlinePathIfLive(book)}
              className="a-btn btn-dark text-sm py-1 px-3 mt-1"
            >
              Buy{' '}
              {price !== null && (
                <span className="text-sm">
                  - {BookUtils.Price.format(price)}
                </span>
              )}
            </AppLink>
          </div>
        </div>{' '}
        {controlOpen.value && (
          <EditorBookControl
            bookPath={BookUtils.inlinePathIfLive(book)}
            onCancel={andFocus(controlOpen.setFalse)}
            onDelete={deleteNode}
          />
        )}
      </NodeViewWrapper>
    )
  }
)
