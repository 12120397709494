/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BookDeleteArgs = {
    bookId: string;
    userId: string;
};
export type UseDeleteBookMutationVariables = {
    args: BookDeleteArgs;
    userId: string;
};
export type UseDeleteBookMutationResponse = {
    readonly bookDelete: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"Book_book">;
    };
};
export type UseDeleteBookMutation = {
    readonly response: UseDeleteBookMutationResponse;
    readonly variables: UseDeleteBookMutationVariables;
};



/*
mutation UseDeleteBookMutation(
  $args: BookDeleteArgs!
  $userId: String!
) {
  bookDelete(args: $args) {
    id
    ...Book_book
  }
}

fragment Book_book on Book {
  __typename
  id
  userId
  bookId
  bookKey {
    bookId
    userId
  }
  timeAdded
  store {
    id
    slug
    name
    data {
      __typename
      links {
        twitter {
          username
        }
      }
    }
  }
  rating
  description
  private {
    identification {
      titleAndAuthor
    }
  }
  data {
    __typename
    ... on BookDataPending {
      sectionId
    }
    ... on BookDataRejected {
      timeWhenRejected
      sectionId
      reason
    }
    ... on BookDataDeleted {
      info {
        slug
        cover {
          url
          aspectRatio
        }
        title
        subtitle
        authors
        id
      }
    }
    ... on BookDataLive {
      sectionId
      info {
        slug
        title
        subtitle
        authors
        cover {
          url
          aspectRatio
        }
        amazonURL
        purchasingDetails {
          variants {
            paperback {
              price
            }
            hardcover {
              price
            }
          }
          shippingCost {
            freePriority
          }
        }
        id
      }
      likes {
        id
        aggregate {
          id
          likeCount
        }
        relativeToStore(userId: $userId) {
          isLiked
        }
      }
      comments {
        id
        count
      }
      review {
        id
        postId
        createdTime
        postData: data {
          lastEditedTime
          stage {
            __typename
            ... on ReviewStagePublished {
              publishedTime
            }
          }
          snippet
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "args"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "args",
    "variableName": "args"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bookId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "BookCover",
  "kind": "LinkedField",
  "name": "cover",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aspectRatio",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authors",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "price",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UseDeleteBookMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Book",
        "kind": "LinkedField",
        "name": "bookDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Book_book"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UseDeleteBookMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Book",
        "kind": "LinkedField",
        "name": "bookDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BookKey",
            "kind": "LinkedField",
            "name": "bookKey",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeAdded",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreData",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StoreLinks",
                    "kind": "LinkedField",
                    "name": "links",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StoreLinksTwitter",
                        "kind": "LinkedField",
                        "name": "twitter",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rating",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Book_Private",
            "kind": "LinkedField",
            "name": "private",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BookIdentification",
                "kind": "LinkedField",
                "name": "identification",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "titleAndAuthor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/)
                ],
                "type": "BookDataPending",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timeWhenRejected",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reason",
                    "storageKey": null
                  }
                ],
                "type": "BookDataRejected",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CatalogBook",
                    "kind": "LinkedField",
                    "name": "info",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "BookDataDeleted",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CatalogBook",
                    "kind": "LinkedField",
                    "name": "info",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amazonURL",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BookPurchasingDetails",
                        "kind": "LinkedField",
                        "name": "purchasingDetails",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BookVariants",
                            "kind": "LinkedField",
                            "name": "variants",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BookVariant",
                                "kind": "LinkedField",
                                "name": "paperback",
                                "plural": false,
                                "selections": (v12/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BookVariant",
                                "kind": "LinkedField",
                                "name": "hardcover",
                                "plural": false,
                                "selections": (v12/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BookShippingCost",
                            "kind": "LinkedField",
                            "name": "shippingCost",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "freePriority",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BookLikes",
                    "kind": "LinkedField",
                    "name": "likes",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BookLikesAggregate",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "likeCount",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Variable",
                            "name": "userId",
                            "variableName": "userId"
                          }
                        ],
                        "concreteType": "BookLikesRelativeToStore",
                        "kind": "LinkedField",
                        "name": "relativeToStore",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isLiked",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Comments",
                    "kind": "LinkedField",
                    "name": "comments",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "count",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Review",
                    "kind": "LinkedField",
                    "name": "review",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdTime",
                        "storageKey": null
                      },
                      {
                        "alias": "postData",
                        "args": null,
                        "concreteType": "ReviewData",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastEditedTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "stage",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "publishedTime",
                                    "storageKey": null
                                  }
                                ],
                                "type": "ReviewStagePublished",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "snippet",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "BookDataLive",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f05cce22d426ea77f21f1a52155bbc17",
    "id": null,
    "metadata": {},
    "name": "UseDeleteBookMutation",
    "operationKind": "mutation",
    "text": "mutation UseDeleteBookMutation(\n  $args: BookDeleteArgs!\n  $userId: String!\n) {\n  bookDelete(args: $args) {\n    id\n    ...Book_book\n  }\n}\n\nfragment Book_book on Book {\n  __typename\n  id\n  userId\n  bookId\n  bookKey {\n    bookId\n    userId\n  }\n  timeAdded\n  store {\n    id\n    slug\n    name\n    data {\n      __typename\n      links {\n        twitter {\n          username\n        }\n      }\n    }\n  }\n  rating\n  description\n  private {\n    identification {\n      titleAndAuthor\n    }\n  }\n  data {\n    __typename\n    ... on BookDataPending {\n      sectionId\n    }\n    ... on BookDataRejected {\n      timeWhenRejected\n      sectionId\n      reason\n    }\n    ... on BookDataDeleted {\n      info {\n        slug\n        cover {\n          url\n          aspectRatio\n        }\n        title\n        subtitle\n        authors\n        id\n      }\n    }\n    ... on BookDataLive {\n      sectionId\n      info {\n        slug\n        title\n        subtitle\n        authors\n        cover {\n          url\n          aspectRatio\n        }\n        amazonURL\n        purchasingDetails {\n          variants {\n            paperback {\n              price\n            }\n            hardcover {\n              price\n            }\n          }\n          shippingCost {\n            freePriority\n          }\n        }\n        id\n      }\n      likes {\n        id\n        aggregate {\n          id\n          likeCount\n        }\n        relativeToStore(userId: $userId) {\n          isLiked\n        }\n      }\n      comments {\n        id\n        count\n      }\n      review {\n        id\n        postId\n        createdTime\n        postData: data {\n          lastEditedTime\n          stage {\n            __typename\n            ... on ReviewStagePublished {\n              publishedTime\n            }\n          }\n          snippet\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '089f351b26952a175595f6d124e0142e';
export default node;
