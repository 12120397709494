import {faTrash} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Dialog} from '@headlessui/react'
import React from 'react'
import {ModalBase} from '../../../../Common/Modal/ModalBase'

export const EditorBookSettings = React.memo(
  ({
    onCancel,
    onDone,
    inlineOnly,
  }: {
    onCancel: () => void
    onDone: (type: 'title' | 'titleAndAuthor' | 'float' | 'block') => void
    inlineOnly: boolean
  }) => {
    return (
      <ModalBase open={true}>
        <div className="">
          <Dialog.Title className="text-lg font-bold mb-2 text-center" as="h2">
            Insert As
          </Dialog.Title>
          <div className="flex flex-col items-start justify-start">
            <button className="text-lg py-2" onClick={() => onDone('title')}>
              Title
            </button>
            <button
              className="text-lg py-2"
              onClick={() => onDone('titleAndAuthor')}
            >
              Title and author
            </button>
            {!inlineOnly && (
              <>
                <button
                  className="text-lg py-2"
                  onClick={() => onDone('float')}
                >
                  Block at start of paragraph
                </button>
                <button
                  className="text-lg py-2"
                  onClick={() => onDone('block')}
                >
                  Centered Block
                </button>
              </>
            )}
          </div>

          <div
            className="mt-2 grid justify-end"
            style={{grid: 'auto/auto auto'}}
          >
            <button className=" btn-md underline" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </ModalBase>
    )
  }
)
