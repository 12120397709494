import { API } from '@indieocean/apidef'
import { assert, fGet } from '@indieocean/utils'
import { Editor } from '@tiptap/core'
import _ from 'lodash'
import { BasicStore } from '../../../Common/Page/WithBasicStoreLive'
import { BookUtils } from '../../Store/Book/BookUtils'
import { Book, isBookDeleted, isBookLive } from '../../Store/Book/Home/Book'

export function getSnippetFromEditor(
  editor: Editor,
  books: Map<string, Book>,
  stores: Map<string, BasicStore | API.SearchDataStore>,
  length: number
) {
  const _helper = (result: string[]) => (node: Editor['state']['doc']) => {
    switch (node.type.name) {
      case 'text':
        result.push(fGet(node.text))
        break
      case 'store': {
        const {userId} = API.readUserIdFromEditorNodeAttrs(node.attrs)
        const store = fGet(stores.get(userId))
        result.push(`${store.name} `)
        break
      }
      case 'link': {
        result.push(`${node.attrs.text}`)
        break
      }
      case 'bookInline': {
        const userBookId = API.readUserBookIdFromEditorNodeAttrs(node.attrs)
        const {includeAuthor} = node.attrs
        const userBookIdKey = API.GQLIds.book(userBookId)
        const book = fGet(books.get(userBookIdKey))
        assert(isBookLive(book) || isBookDeleted(book))
        const {title, authors} = book.data.info
        const text = includeAuthor
          ? `${title} by ${BookUtils.Authors.toStr(authors)} `
          : title
        result.push(text)
        break
      }
      default:
        const currResult: string[] = []
        node.descendants(_helper(currResult))
        const currJoined =
          node.type.name === 'blockquote'
            ? ` "${_join(currResult)}"`
            : ` ${_join(currResult)}`

        result.push(currJoined)
    }
    // Don't recurse. That is handled inside the switch by calling descendents again.
    return false
  }

  const result: string[] = []
  editor.state.doc.descendants(_helper(result))
  return _.truncate(_join(result), {length})
}

const _join = (x: string[]) => x.join('').replace(/\s\s/g, ' ').trim()
