import {EditorContent} from '@tiptap/react'
import format from 'date-fns/format'
import React from 'react'
import {EditorRenderProps} from '../Editor'

export const CompactEditorDisplay = React.memo(
  ({
    propsFromEditor,
    className = '',
  }: {
    propsFromEditor: EditorRenderProps
    className?: string
  }) => {
    return (
      <div className={`${className}`}>

        <EditorContent editor={propsFromEditor.editor} />
      </div>
    )
  }
)
