import React from 'react'
import {Cover} from '../../../../Common/Cover'
import {Comments} from '../../../Common/Comments/Comments'
import {Comments_comments$key} from '../../../Common/Comments/__generated__/Comments_comments.graphql'
import { useScrollTo } from '../../../Common/UseScrollTo'
import {BookRating} from '../../BookRating'
import {BookUtils} from '../BookUtils'
import {BookActionBar} from '../Common/BookActionBar'
import {BookReviewSnippet} from '../Common/BookReviewSnippet'
import {BookLive} from './Book'
import {BookBuyBlock} from './BookBuyBlock'

export const BookAfterData = React.memo(
  ({
    book,
    commentsKey,
  }: {
    book: BookLive
    commentsKey: Comments_comments$key
  }) => {
    const {info} = book.data
    const handleScrollTo = useScrollTo()

    return (
      <div className={`body-grid3-wide`}>
        <div className="body-grid-content">
          <div className="hidden sm:flex justify-center gap-x-16 vertNav:gap-x-36 mt-28">
            <div className="flex flex-col justify-center">
              <Cover cover={book.data.info.cover} size={["w-[150px]", "vertNav:w-[200px]"]} />
            </div>
            <div className="max-w-[350px]">
              <div className="">
                <h2 className="font-medium text-lg vertNav:text-xl">
                  {info.title}
                </h2>
                {info.subtitle.length > 0 && (
                  <h2 className="vertNav:text-lg">{info.subtitle}</h2>
                )}
                <h3 className="font-medium vertNav:text-lg">
                  {BookUtils.Authors.toStr(info.authors)}
                </h3>
              </div>
              <BookRating className="mt-2" rating={book.rating} />
              <BookReviewSnippet
                className="mt-4 text-lg"
                book={book}
                pathIfQuickReview={null}
                background="page"
              />
              <BookActionBar
                className="mt-2"
                book={book}
                backAfterDelete
                showBuy={false}
                onScrollToComments={()=>handleScrollTo('comments')}
              />
              <BookBuyBlock className="mt-8" book={book} />
            </div>
          </div>
          <div className="sm:hidden mt-10">
            <div className="flex gap-x-5">
              <Cover cover={book.data.info.cover} size="w-[100px]" />
              <div className="">
                <h2 className="font-medium text-lg">
                  {info.title}
                </h2>
                {info.subtitle.length > 0 && (
                  <h2 className="">{info.subtitle}</h2>
                )}
                <h3 className="font-medium">
                  {BookUtils.Authors.toStr(info.authors)}
                </h3>
              </div>
            
            </div>
            <div className="">
              <BookRating className="mt-2" rating={book.rating} />
              <BookReviewSnippet
                className="mt-4 text-lg"
                book={book}
                pathIfQuickReview={null}
                background="page"
              />
              <BookActionBar
                className="mt-2"
                book={book}
                backAfterDelete
                showBuy={false}
                onScrollToComments={()=>handleScrollTo('comments')}
              />
              <BookBuyBlock className="mt-8" book={book} />
            </div>
          </div>
          <div className="mt-10 sm:mt-20 sm:mx-10 vertNav:mx-0">
            <Comments
              className=""
              target={book}
              commentsKey={commentsKey}
              labelSize="text-xl"
            />
          </div>
        </div>
       
      </div>
    )
  }
)
