import { Dialog } from '@headlessui/react'
import { API } from '@indieocean/apidef'
import { assertFalse } from '@indieocean/utils'
import { isMobile } from 'is-mobile'
import React, { useState } from 'react'
import { graphql } from 'relay-runtime'
import { CircleProgressIfRunning } from '../../../../Common/Tools/CircleProgressIfRunning'
import { uploadFile } from '../../../../Common/Tools/UploadFile'
import { useGQLMutation } from '../../../../Common/Tools/UseGQLMutation'
import { useGlobalToasts } from '../../../../Common/WithGlobalToasts'
import { useFUser } from '../../../../Common/WithUser'
import { useBooleanStateObj } from '../../../../Utils/UseBooleanStateObj'
import { useStateObj } from '../../../../Utils/UseStateObj'
import { EditorImageSettings } from './EditorImageSettings'
import { EditorImageInputGetURLMutation } from './__generated__/EditorImageInputGetURLMutation.graphql'

const getURLMutation = graphql`
  mutation EditorImageInputGetURLMutation($args: PostGetImageUploadURLArgs!) {
    postGetImageUploadURL(args: $args) {
      url
      filename
      fields
    }
  }
`

export const EditorImageInput = React.memo(
  ({
    onCancel,
    onImage,
  }: {
    onCancel: () => void
    onImage: (
      filename: string,
      width: 'sm' | 'md' | 'lg',
      caption: string
    ) => void
  }) => {
    const [state, setState] = useState<
      {type: 'get'} | {type: 'edit'; filename: string}
    >({type: 'get'})
    return state.type === 'get' ? (
      <_Get
        onCancel={onCancel}
        onImage={filename => setState({type: 'edit', filename})}
      />
    ) : (
      <EditorImageSettings
        width="lg"
        caption=""
        onDelete={null}
        onCancel={onCancel}
        onDone={(width, caption) => onImage(state.filename, width, caption)}
      />
    )
  }
)

const imageMimeTypes = ['image/jpeg', 'image/png']
const _Get = React.memo(
  ({
    onCancel,
    onImage,
  }: {
    onCancel: () => void
    onImage: (filename: string) => void
  }) => {
    const {userId} = useFUser()
    const dragCountState = useStateObj(0)
    const isRunningState = useBooleanStateObj(false)
    const {errorToast} = useGlobalToasts()
    const [commitGetURL] = useGQLMutation<EditorImageInputGetURLMutation>(
      getURLMutation,
      'soft'
    )
    const handleFile = (file: File) => {
      if (file.size > API.MAX_POST_IMAGES_FILE_SIZE) {
        const inMB = Math.round(API.MAX_POST_IMAGES_FILE_SIZE / 1000000)
        errorToast(`Image has to be less than ${inMB} MB`)
      } else {
        const ext =
          file.type === 'image/jpeg'
            ? '.jpg'
            : file.type === 'image/png'
            ? '.png'
            : assertFalse()
        isRunningState.setTrue()
        commitGetURL({
          variables: {args: {userId, ext}},
          onCompleted: ({postGetImageUploadURL: result}) => {
            const {url, filename, fields} = result
            uploadFile(url, fields, file)
              .then(() => {
                isRunningState.setFalse()
                onImage(filename)
                return
              })
              .catch(() => {
                isRunningState.setFalse()
                errorToast('Something went wrong.')
              })
          },
          onError: isRunningState.setFalse,
        })
      }
    }
    return (
      <Dialog open onClose={() => {}} className="modal-base ">
        <Dialog.Overlay className="modal-overlay" />
        <div
          className={`bg-pageBG text-pageFG z-10 rounded-lg
                       p-2 shadow-xl m-20 sm:m-20 `}
          style={{minWidth: 'max(min(calc(100vw - 20px), 500px), 75vw)'}}
          onDragEnter={e => dragCountState.set(x => ++x)}
          onDragLeave={e => dragCountState.set(x => --x)}
          onDragOver={e => e.preventDefault()}
          onDrop={e => {
            dragCountState.set(0)
            if (e.dataTransfer.files.length !== 1) return
            const file = e.dataTransfer.files[0]
            if (imageMimeTypes.indexOf(file.type) === -1) return
            e.preventDefault()
            handleFile(file)
          }}
        >
          <div
            className={`h-[75vh] grid
          ${
            dragCountState.value > 0
              ? 'border-4 border-inputBorder border-dashed lighten-2'
              : ''
          }`}
            style={{grid: '1fr auto /auto'}}
          >
            <input
              id="ImageUpload"
              type="file"
              accept="image/jpeg, image/png"
              className="fixed top"
              style={{top: ' -100em'}}
              onChange={e => {
                const file = e.target.files?.item(0)
                if (file) handleFile(file)
              }}
            />
            <CircleProgressIfRunning
              isRunning={isRunningState.value}
              className={`flex flex-col justify-center items-center rounded-lg
            `}
            >
              <div className="grid items-center gap-y-2 text-center">
                {isMobile() ? (
                  <></>
                ) : (
                  <>
                    <h2 className="text-lg">Drag and drop</h2>
                    <h2 className="text-base">or</h2>
                  </>
                )}
                <label
                  htmlFor="ImageUpload"
                  className="cursor-pointer a-btn btn-md btn-dark"
                >
                  Select a file
                </label>
              </div>
            </CircleProgressIfRunning>
            <div className="flex justify-end">
              <button className="btn-lg underline" onClick={onCancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
)
