import {faTwitter} from '@fortawesome/free-brands-svg-icons'
import {faEllipsisH, faHeart, faComment} from '@fortawesome/pro-light-svg-icons'
import {faHeart as faHeartSolid} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {assert, noCase} from '@indieocean/utils'
import React, {ReactNode} from 'react'
import {Config} from '../../../../../Config'
import {storePath} from '../../../../Common/Page/WithBasicStoreLive'
import {AppLink} from '../../../../Common/Tools/AppLink'
import {MenuButton} from '../../../../Common/Tools/MenuButton'
import {assertAuthorized} from '../../../../Common/WithRelay'
import {useFUser, useUser} from '../../../../Common/WithUser'
import {BookUtils} from '../BookUtils'
import {useDeleteBook} from '../EditBook/UseDeleteBook'
import {Book, BookAdmin, isBookLive, isBookOwn} from '../Home/Book'
import {useBookToggleLike} from '../UseBookToggleLike'
import {useSubBookLikesAggregate} from '../UseSubBookLikesAggregate'

export const BookActionBar = React.memo(
  ({
    book,
    className = '',
    backAfterDelete,
    showBuy,
    onScrollToComments,
  }: {
    book: Book
    className?: string
    backAfterDelete: boolean
    showBuy: boolean
    onScrollToComments: (() => void) | null
  }) => {
    const userId = useUser()?.userId
    const isOwn = isBookOwn(book, userId ?? null)
    const handleLike = useBookToggleLike(book)

    useSubBookLikesAggregate(book)
    const [
      likes,
      isLiked,
      commentCount,
      shareOnTwitterParams,
      purchasingDetails,
    ] = (() => {
      switch (book.data.__typename) {
        case 'BookDataWithdrawn':
        case 'BookDataPending':
        case 'BookDataRejected':
        case 'BookDataDeleted':
          return [0, false, null, null, null] as const
        case 'BookDataLive':
          const prefix = isOwn
            ? 'My thoughts on'
            : book.store.data?.links.twitter
            ? `Review by @${book.store.data.links.twitter.username} of`
            : `Review by ${book.store.name} of`

          return [
            book.data.likes.aggregate.likeCount,
            book.data.likes.relativeToStore?.isLiked ?? false,
            book.data.comments.count,
            new URLSearchParams({
              text: `${Config.client.urls.app(
                storePath(book.store).books({
                  scrollTo: `book-${book.bookId}`,
                })
              )}
${prefix} ${book.data.info.title} by ${BookUtils.Authors.etAlStr(
                book.data.info.authors
              )}

${book.data.review?.postData?.snippet ?? book.description}`,
              related: 'indieocean_io',
            }).toString(),
            book.data.info.purchasingDetails,
          ] as const
        default:
          noCase(book.data)
      }
    })()

    return (
      <div className={`${className} flex justify-between`}>
        <div className="flex items-center ">
          <button
            className={`flex items-center pl-1 pr-2`}
            onClick={handleLike}
          >
            {
              <FontAwesomeIcon
                className="text-lg mr-2"
                icon={isLiked ? faHeartSolid : faHeart}
              />
            }
          </button>
          <AppLink className={`a-btn  pr-4`} path={BookUtils.path(book).likes}>
            {likes}
          </AppLink>
          {commentCount !== null && (
            <AppLink
              className={`flex items-center gap-x-4 px-4`}
              path={
                onScrollToComments ??
                BookUtils.path(book).root({scrollTo: 'comments'})
              }
            >
              {<FontAwesomeIcon className="text-lg" icon={faComment} />}
              <h2 className="">{commentCount}</h2>
            </AppLink>
          )}
          {shareOnTwitterParams && (
            <MenuButton className=" px-4" origin="bottomStart" overlap>
              <span className="material-icons-outlined text-lg">share</span>
              {[
                {
                  action: `https://twitter.com/intent/tweet?`,
                  text: 'Share on Twitter',
                  faIcon: faTwitter,
                },
              ]}
            </MenuButton>
          )}
          {isOwn && (
            <_EditMenu
              book={book}
              className="px-4"
              backAfterDelete={backAfterDelete}
            >
              <FontAwesomeIcon className="text-xl" icon={faEllipsisH} />
            </_EditMenu>
          )}
        </div>

        <div className="">
          {showBuy && isBookLive(book) && (
            <AppLink
              className={` self-end py-0.5 px-3 mr-4 border border-gray-500 rounded-full text-sm text-center`}
              path={BookUtils.path(book).root()}
            >
              Buy
              {purchasingDetails && (
                <span className="text-xs lighten ml-1">
                  {BookUtils.Price.formatLower(purchasingDetails)}
                </span>
              )}
            </AppLink>
          )}
        </div>
      </div>
    )
  }
)

const _EditMenu = React.memo(
  ({
    book,
    children,
    className = '',
    backAfterDelete,
  }: {
    book: BookAdmin
    className?: string
    children: ReactNode
    backAfterDelete: boolean
  }) => {
    const user = useFUser()
    assertAuthorized(user.userId === book.userId)
    const [handleDelete, deleteAlert] = useDeleteBook(
      book,
      backAfterDelete ? 'backAfterDelete' : 'noBackAfterDelete'
    )

    const menuItemsList = {
      editTitleAndAuthor: {
        action: BookUtils.path(book).editTitleAndAuthor,
        text: 'Edit Title and Author (or URL)',
      },
      editQuickReview: {
        action: BookUtils.path(book).editQuickReview,
        text: 'Edit Quick Review',
      },
      editFullLengthReview: {
        action: BookUtils.path(book).review.edit,
        text: 'Edit Full Length Review',
      },
      addFullLengthReview: {
        action: BookUtils.path(book).addReview,
        text: 'Add a Full Length Review',
      },
      editReview: {
        action: BookUtils.path(book).review.edit,
        text: 'Edit Review',
      },
      editRating: {
        action: BookUtils.path(book).editRating,
        text: 'Edit Rating',
      },
      moveSection: {
        action: BookUtils.path(book).moveSection,
        text: 'Move to Another Section',
      },
      delete: {action: handleDelete, text: 'Delete Book'},
    }

    type MenuItemName = keyof typeof menuItemsList
    const menuItemNames = ((): MenuItemName[] => {
      switch (book.data.__typename) {
        case 'BookDataWithdrawn':
        case 'BookDataDeleted':
          assert(false)
        case 'BookDataPending':
          return [
            'editTitleAndAuthor',
            'editQuickReview',
            'editRating',
            'moveSection',
            'delete',
          ]
        case 'BookDataRejected':
          return ['delete']
        case 'BookDataLive':
          const reviewItems: MenuItemName[] = book.data.review?.postData
            ? book.data.review.postData.stage.__typename === 'ReviewStageDraft'
              ? ['editQuickReview', 'editFullLengthReview']
              : book.data.review.postData.stage.__typename ===
                'ReviewStagePublished'
              ? ['editReview']
              : noCase(book.data.review.postData.stage)
            : ['editQuickReview', 'addFullLengthReview']
          return [...reviewItems, 'editRating', 'moveSection', 'delete']
        default:
          noCase(book.data)
      }
    })()

    return (
      <>
        <MenuButton className={`${className}`} origin="bottomStart" overlap>
          <>{children}</>
          {menuItemNames.map(x => menuItemsList[x])}
        </MenuButton>
        {deleteAlert}
      </>
    )
  }
)
