import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { API } from '@indieocean/apidef'
import { TextInput } from '../../../../Common/Form/TextInput'
import { ModalBase } from '../../../../Common/Modal/ModalBase'
import { useInputControl } from '../../../../Utils/UseInputControl'

export const EditorImageSettings = React.memo(
  ({
    width: startingWidth,
    caption: startingCaption,
    onCancel,
    onDone,
    onDelete,
  }: {
    width: 'sm' | 'md' | 'lg'
    caption: string
    onCancel: () => void
    onDone: (size: 'sm' | 'md' | 'lg', caption: string) => void
    onDelete: (() => void) | null
  }) => {
    const [width, setWidth] = useState<'sm' | 'md' | 'lg'>(startingWidth)
    const caption = useInputControl(
      startingCaption ?? '',
      API.Decode.trimmed('small')
    )
    const handleDone = () => {
      if (!caption.validation.ok) {
        caption.setValidating(true)
        return
      }
      onDone(width, caption.validation.result)
    }
    return (
      <ModalBase open={true}>
        <div className="p-2">
          <div className="grid gap-x-8" style={{grid: 'auto / auto 1fr '}}>
            <h2 className="">Width</h2>

            <div className="grid gap-x-4" style={{grid: 'auto / 1fr 1fr 1fr '}}>
              <button
                className={`btn-sm ${
                  width === 'sm' ? 'btn-dark' : 'btn-outline'
                }`}
                onClick={() => setWidth('sm')}
              >
                Small
              </button>
              <button
                className={`btn-sm ${
                  width === 'md' ? 'btn-dark' : 'btn-outline'
                }`}
                onClick={() => setWidth('md')}
              >
                Medium
              </button>
              <button
                className={`btn-sm ${
                  width === 'lg' ? 'btn-dark' : 'btn-outline'
                }`}
                onClick={() => setWidth('lg')}
              >
                Full
              </button>
            </div>
          </div>
          <TextInput
            className="mt-4"
            label={<h2 className="">Caption</h2>}
            control={caption}
            onNext={handleDone}
          />
          <div className="mt-8 grid" style={{grid: 'auto/1fr auto auto'}}>
            <div className="">
              {onDelete && (
                <button className=" py-2 sm:py-0 px-4" onClick={onDelete}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              )}
            </div>
            <button className=" btn-md underline" onClick={onCancel}>
              Cancel
            </button>
            <button className=" btn-md btn-dark" onClick={handleDone}>
              {onDelete ? 'Done' : 'Insert'}
            </button>
          </div>
        </div>
      </ModalBase>
    )
  }
)
