import {Dialog} from '@headlessui/react'
import {API} from '@indieocean/apidef'
import { fGet } from '@indieocean/utils'
import {useCombobox} from 'downshift'
import React, {useState} from 'react'
import {ProfileImage} from '../../../../Common/ProfileImage'
import {SearchHighlight} from '../../Search/ProcessAlgoliaHighlight'
import {useSearchStores} from '../../Search/UseSearchStores'

export const EditorStoreInput = React.memo(
  ({
    onCancel,
    onStore,
  }: {
    onCancel: () => void
    onStore: (store: API.SearchDataStore) => void
  }) => {
    return (
      <Dialog
        open
        onClose={onCancel}
        className="fixed z-50 inset-0 overflow-y-auto flex flex-col justify-start items-center "
      >
        <Dialog.Overlay className="modal-overlay" />
        <div
          className="bg-pageBG text-pageFG z-10 rounded-lg w-[75%]
     py-1 px-2 shadow-xl mt-20 sm:mt-20"
          style={{minWidth: 'min(calc(100vw - 20px), 500px)'}}
        >
          <_Search onCancel={onCancel} onStore={onStore} />
        </div>
      </Dialog>
    )
  }
)

// Somehow, if the root element is <Dialog> downshift complains about refs not being
// set correctly. Using _Search solved this.
const _Search = React.memo(
  ({
    onCancel,
    onStore,
  }: {
    onCancel: () => void
    onStore: (store: API.SearchDataStore) => void
  }) => {
    const [term, setTerm] = useState('')
    const results = useSearchStores(term)
    const {
      isOpen: isSuggestOpen,
      selectedItem,
      getLabelProps,
      getToggleButtonProps,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      highlightedIndex,
      getItemProps,
    } = useCombobox({
      items: results,
      itemToString: x =>
        x === null ? 'null' : `${x.store.name} (@${x.store.slug} )`,
      onInputValueChange: ({inputValue}) => 
        setTerm(inputValue ?? '')
      ,
      onSelectedItemChange:(({selectedItem})=>onStore(fGet(selectedItem).store))
    })

    return (
      <div className="">
        <div className="" {...getComboboxProps({})}>
          <input
            {...getInputProps({
              className:
                'w-full border-b-2 border-inputBorder outline-none text-lg p-1 mt-3',
            })}
          />
        </div>
        <ul {...getMenuProps({className: 'pt-2'})}>
          {results.map((result, index) => (
            <li
              className={`px-1 rounded-lg 
              ${index === highlightedIndex ? 'bg-sectionLightBG' : ''}`}
              // style={{grid: 'auto 1fr'}}
              key={`${result.store.user.userId}`}
              {...getItemProps({item: result, index})}
            >
              <button
                className="py-3 grid grid-flow-col gap-x-4 justify-start items-center text-left w-full"
                onClick={() => onStore(result.store)}
              >
                <ProfileImage store={result.store} size={40} />
                <div className="">
                  <h2 className="text-lg-tight">
                    <_Highlight
                      notMatchClassName="lighten"
                      matchClassName="font-bold"
                    >
                      {result.highlighted.name}
                    </_Highlight>
                  </h2>
                  <h2 className="text-base">
                    <span className="lighten-2">@</span>{' '}
                    <_Highlight
                      notMatchClassName="lighten"
                      matchClassName="font-bold"
                    >
                      {result.highlighted.slug}
                    </_Highlight>
                  </h2>
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }
)

const _Highlight = React.memo(
  ({
    children,
    notMatchClassName,
    matchClassName,
  }: {
    children: SearchHighlight[]
    notMatchClassName: string
    matchClassName: string
  }) => {
    return (
      <>
        {children.map(({text, isMatch}, index) => (
          <span
            className={isMatch ? matchClassName : notMatchClassName}
            key={index}
          >
            {text}
          </span>
        ))}
      </>
    )
  }
)
