import { faExternalLink } from '@fortawesome/pro-light-svg-icons'
import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FGet } from '@indieocean/utils'
import { mergeAttributes, Node, NodeConfig } from '@tiptap/core'
import { Editor, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react'
import React from 'react'
import { ModalBase } from '../../../../Common/Modal/ModalBase'
import { useBooleanStateObj } from '../../../../Utils/UseBooleanStateObj'
import { EditorLinkInput } from '../Input/EditorLinkInput'

export type EditorLinkNodeOptions = Record<string, never>

export const EditorLinkNode = Node.create<EditorLinkNodeOptions>({
  name: 'link',
  priority: 100,
  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      href: {default: null},
      text: {default: null},
    }
  },

  parseHTML() {
    return [{tag: 'a[data-link]'}]
  },

  renderHTML({HTMLAttributes, node}) {
    return [
      'a',
      mergeAttributes(
        {'data-link': ''},
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(_Component)
  },
})

const _Component = React.memo(
  ({node, deleteNode, editor, updateAttributes}: {
    node: Parameters<FGet<NodeConfig['renderHTML']>>[0]['node']
    deleteNode: () => void
    editor: Editor
    updateAttributes: (attrs: Record<string, any>) => void
  }) => {
    const {href, text} = node.attrs

    const detailOpen = useBooleanStateObj(false)
    const editOpen = useBooleanStateObj(false)
    const andFocus = (fn: () => void) => () => {
      fn()
      window.setTimeout(() => editor.chain().focus().run(), 0)
    }
    return (
      <NodeViewWrapper className="inline">
        {editor.isEditable ? (
          <button className="underline" onClick={detailOpen.setTrue}>
            {text}
          </button>
        ) : (
          <a className="underline  outline-none" href={href} rel="noopener" target="_blank">
            {text}
          </a>
        )}
        <ModalBase
          open={detailOpen.value}
          onClose={andFocus(detailOpen.setFalse)}
        >
          <div className="flex justify-between items-center px-3 sm:px-0">
            <a
              href={href}
              rel="noopener"
              target="_blank"
              onClick={detailOpen.setFalse}
            >
              <span className="underline">Open Link </span>
              <FontAwesomeIcon className="ml-2 text-sm" icon={faExternalLink} />
            </a>
            <div className="grid grid-flow-col">
              <button className="py-3 sm:py-1 px-4" onClick={deleteNode}>
                <FontAwesomeIcon icon={faTrash} />
              </button>
              <button
                className="py-3 sm:py-1 pl-4"
                onClick={() => {
                  detailOpen.setFalse()
                  editOpen.setTrue()
                }}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
            </div>
          </div>
        </ModalBase>
        {editOpen.value && (
          <EditorLinkInput
            text={text}
            url={href}
            onCancel={andFocus(editOpen.setFalse)}
            onLink={(text, href) => {
              updateAttributes({text, href})
              andFocus(editOpen.setFalse)()
            }}
          />
        )}
      </NodeViewWrapper>
    )
  }
)
