import {assert, StrUtils} from '@indieocean/utils'
import {TextareaAutosize} from '@material-ui/core'
import {EditorContent} from '@tiptap/react'
import React from 'react'
import {CircleProgressIfRunning} from '../../../../Common/Tools/CircleProgressIfRunning'
import {InputControl} from '../../../../Utils/UseInputControl'
import {EditorRenderProps} from '../Editor'
import {CompactEditorMenuOnNarrow} from './CompactEditorMenuOnNarrow'
import {CompactEditorMenuOnWide} from './CompactEditorMenuOnWide'

export const CompactEditor = React.memo(
  ({
    propsFromEditor,
    title,
    className = '',
  }: {
    propsFromEditor: EditorRenderProps
    title: InputControl | null
    className?: string
  }) => {
    const {type} = propsFromEditor
    assert(type === 'minimal' || type === 'compact')
    return (
      <CircleProgressIfRunning
        className={`${className} flex flex-col items-start`}
        isRunning={propsFromEditor.isRunningState.isTrue}
        inner={{className: 'w-full'}}
      >
        <CompactEditorMenuOnNarrow {...propsFromEditor} />
        <div
          className={`${
            type === 'minimal' ? 'border' : 'border-2'
          } border-inputBorder  rounded-b-lg rounded-tr-lg vertNav:rounded-tl-lg  w-full`}
        >
          <div
            className={`${
              type === 'minimal' ? 'min-h-[70px] p-1 ' : 'min-h-[250px] p-2 '
            } grid`}
            style={{grid: title ? 'auto auto 1fr / auto' : 'auto 1fr /auto'}}
          >
            {title && (
              <TextareaAutosize
                className="text-lg  font-semibold mb-2 outline-none w-full "
                value={title.value}
                onChange={e =>
                  title.setValue(
                    StrUtils.removeVerticalWhitespace(e.target.value)
                  )
                }
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    propsFromEditor.callbacks.onRefocus()
                  }
                }}
                placeholder="Title"
              />
            )}
            <EditorContent editor={propsFromEditor.editor} />
            <div
              className=""
              onClick={() => {
                propsFromEditor.callbacks.onCursorToEnd()
              }}
            ></div>
          </div>
          <CompactEditorMenuOnWide {...propsFromEditor} />
        </div>
      </CircleProgressIfRunning>
    )
  }
)
