/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CommentKeyIn = {
    commentId: string;
    userId: string;
};
export type UseSubCommentLikesAggregateSubscriptionVariables = {
    commentKey: CommentKeyIn;
};
export type UseSubCommentLikesAggregateSubscriptionResponse = {
    readonly commentLikesAggregate: {
        readonly id: string;
        readonly likeCount: number;
    };
};
export type UseSubCommentLikesAggregateSubscription = {
    readonly response: UseSubCommentLikesAggregateSubscriptionResponse;
    readonly variables: UseSubCommentLikesAggregateSubscriptionVariables;
};



/*
subscription UseSubCommentLikesAggregateSubscription(
  $commentKey: CommentKeyIn!
) {
  commentLikesAggregate(commentKey: $commentKey) {
    id
    likeCount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "commentKey"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "commentKey",
        "variableName": "commentKey"
      }
    ],
    "concreteType": "CommentLikesAggregate",
    "kind": "LinkedField",
    "name": "commentLikesAggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "likeCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UseSubCommentLikesAggregateSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UseSubCommentLikesAggregateSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "df2abfa112952781b18dea8f1c48dc36",
    "id": null,
    "metadata": {},
    "name": "UseSubCommentLikesAggregateSubscription",
    "operationKind": "subscription",
    "text": "subscription UseSubCommentLikesAggregateSubscription(\n  $commentKey: CommentKeyIn!\n) {\n  commentLikesAggregate(commentKey: $commentKey) {\n    id\n    likeCount\n  }\n}\n"
  }
};
})();
(node as any).hash = '9658a1abe075c8d9b8ea182f13a2fb00';
export default node;
