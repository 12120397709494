import { useMemo } from 'react'
import { graphql, useSubscription } from 'react-relay'
import { Book, BookLive } from './Home/Book'
import { UseSubBookLikesAggregateSubscription } from './__generated__/UseSubBookLikesAggregateSubscription.graphql'

const subscription = graphql`
  subscription UseSubBookLikesAggregateSubscription(
    $userId: String!
    $bookId: String!
  ) {
    bookLikesAggregate(userId: $userId, bookId: $bookId) {
      id
      likeCount
    }
  }
`

export function useSubBookLikesAggregate(book: Book) {
  useSubscription<UseSubBookLikesAggregateSubscription>(
    useMemo(
      () => ({
        subscription,
        variables: {userId: book.userId, bookId: book.bookId},
      }),
      [book.bookId, book.userId]
    )
  )
}
