import React, {ReactNode, useState} from 'react'
import {graphql} from 'relay-runtime'
import {ConfirmAlert} from '../../../Common/Modal/ConfirmAlert'
import {useGQLMutation} from '../../../Common/Tools/UseGQLMutation'
import {useGlobalToasts} from '../../../Common/WithGlobalToasts'
import {User} from '../../../Common/WithUser'
import {NotDeletedComment} from './Comment'
import {DeleteCommentMutation} from './__generated__/DeleteCommentMutation.graphql'

const mutation = graphql`
  mutation DeleteCommentMutation($args: CommentDeleteArgs!, $userId: String!) {
    commentDelete(args: $args) {
      ...Comment_comment
    }
  }
`

export const DeleteComment = React.memo(
  ({
    comment,
    children,
    user,
  }: {
    user: User
    comment: NotDeletedComment
    children: (handler: () => void) => ReactNode
  }) => {
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [commit, isRunning] = useGQLMutation<DeleteCommentMutation>(
      mutation,
      'soft'
    )
    const {infoToast} = useGlobalToasts()

    const handleDelete = () => {
      commit({
        variables: {
          args: {commentKey: comment.commentKey},
          userId: user.userId,
        },
        onCompleted: () => infoToast('Deleted'),
      })
    }
    return (
      <>
        {children(() => setIsAlertOpen(true))}
        <ConfirmAlert
          title="Confirm Delete"
          confirmText="Delete"
          isRunning={isRunning}
          isWarning
          onConfirm={handleDelete}
          onCancel={() => setIsAlertOpen(false)}
          open={isAlertOpen}
        >
          Are you sure you want to delete this comment?
        </ConfirmAlert>
      </>
    )
  }
)
