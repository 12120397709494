import {Dialog} from '@headlessui/react'
import React from 'react'
import { CircleProgressIfRunning } from '../Tools/CircleProgressIfRunning'
import { ModalBase } from './ModalBase'
export const ConfirmAlert = React.memo(
  ({
    title,
    children,
    confirmText,
    isWarning = false,
    isRunning = false,
    onCancel,
    onConfirm,
    open,
  }: {
    title: string
    children: string | string[]
    confirmText: string
    isWarning?: boolean
    isRunning?: boolean
    onCancel: () => void
    onConfirm: () => void
    open: boolean
  }) => {
    if (!open) return <></>
    return (
      <ModalBase>
        <CircleProgressIfRunning isRunning={isRunning}>
          <Dialog.Title className="modal-title">{title}</Dialog.Title>
          <Dialog.Description className="modal-p">
            {children}{' '}
          </Dialog.Description>
          <div className="flex justify-end mt-4">
            <button className="btn-md " onClick={onCancel} disabled={isRunning}>
              Cancel
            </button>
            <button
              className={`btn-md relative ${
                isWarning ? 'btn-dark-warnBG' : 'btn-dark'
              }`}
              disabled={isRunning}
              onClick={onConfirm}
            >
              {confirmText}
            </button>
          </div>
        </CircleProgressIfRunning>
      </ModalBase>
    )
  }
)
