import {FGet, noCase} from '@indieocean/utils'
import {Editor, mergeAttributes, Node, NodeConfig} from '@tiptap/core'
import {NodeViewWrapper, ReactNodeViewRenderer} from '@tiptap/react'
import React from 'react'
import {Config} from '../../../../../Config'
import {useBooleanStateObj} from '../../../../Utils/UseBooleanStateObj'
import {EditorImageSettings} from '../Input/EditorImageSettings'

export interface EditorImageNodeOptions {
  HTMLAttributes: Record<string, any>
  imagePathFn:(filename:string)=>string
}

export const EditorImageNode = Node.create<EditorImageNodeOptions>({
  name: 'image',
  priority: 100,
  group: 'block',
  selectable: true,

  addAttributes() {
    return {
      filename: {default: null},
      size: {default: null}, // 'sm'|'md'|'lg'
      caption: {default: null}, // string|null
    }
  },

  parseHTML() {
    return [{tag: 'img[data-image]'}]
  },

  renderHTML({HTMLAttributes, node}) {
    return ['img', mergeAttributes({'data-image': ''}, HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(_Component)
  },
})

const _Component = React.memo(
  ({
    node,
    deleteNode,
    extension,
    editor,
    updateAttributes,
  }: {
    node: Parameters<FGet<NodeConfig['renderHTML']>>[0]['node']
    deleteNode: () => void
    editor: Editor
    updateAttributes: (attrs: Record<string, any>) => void
    extension: Node<EditorImageNodeOptions>
  }) => {
    // Convert userId to a string. It shows up as a number if parsed from HTML (eg. pasting.)
    const {filename, size, caption} = node.attrs as {
      filename: string
      size: 'sm' | 'md' | 'lg'
      caption: string
    }
    const {imagePathFn} = extension.options
    const editOpen = useBooleanStateObj(false)
    const andFocus = (fn: () => void) => () => {
      fn()
      window.setTimeout(() => editor.chain().focus().run(), 0)
    }
    const src = imagePathFn(filename)
    const sizeClass =
      size === 'sm'
        ? 'w-[65%] sm:w-[50%]'
        : size === 'md'
        ? 'w-[75%]'
        : size === 'lg'
        ? 'w-[100%]'
        : noCase(size)
    return (
      <NodeViewWrapper className="flex justify-center">
        <div
          className={`${sizeClass} my-5
          ${editor.isEditable ? 'cursor-pointer' : ''}`}
        >
          <img
            src={src}
            className={`block `}
            onClick={editor.isEditable ? editOpen.setTrue : undefined}
          />
          {caption.length > 0 && <p className="mt-2 lighten text-sm text-center">{caption}</p>}
        </div>
        {editOpen.value && (
          <EditorImageSettings
            width={size}
            caption={caption}
            onDelete={deleteNode}
            onCancel={andFocus(editOpen.setFalse)}
            onDone={(size, caption) => {
              updateAttributes({size, caption, filename})
              andFocus(() => {
                editOpen.setFalse()
              })()
            }}
          />
        )}
      </NodeViewWrapper>
    )
  }
)
