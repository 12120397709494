import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {
  faAt,
  faBold,
  faBookOpen,
  faImage,
  faItalic,
  faLink,
  faQuoteLeft,
} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { assert } from '@indieocean/utils'
import React from 'react'
import {EditorRenderProps} from '../Editor'

export const CompactEditorMenuOnWide = React.memo(
  ({editor, callbacks: cb, type}: EditorRenderProps) => {
    assert(type === 'compact' || type === 'minimal')
    const _Btn = React.memo(
      ({
        icon,
        active = false,
        onClick,
      }: {
        icon: IconProp
        active?: boolean
        onClick: () => void
      }) => {
        return (
          <button
            className={`${type ==='minimal'?'w-[40px] py-0.5': 'w-[50px] py-2'}
            ${active ? ' bg-buttonDarkBG text-buttonDarkFG' : ''}`}
            onClick={onClick}
          >
            <FontAwesomeIcon icon={icon} />
          </button>
        )
      }
    )
    
    return (
      <div className={` hidden vertNav:flex flex-row justify-start`}>
        <_Btn
          active={editor.isActive('bold')}
          onClick={cb.onBold}
          icon={faBold}
        />
        <_Btn
          onClick={cb.onItalic}
          active={editor.isActive('italic')}
          icon={faItalic}
        />
        <_Btn onClick={cb.onBook} icon={faBookOpen} />
        <_Btn onClick={cb.onStore} icon={faAt} />
        <_Btn onClick={cb.onLink} icon={faLink} />
        {type === 'compact' && <_Btn onClick={cb.onImage} icon={faImage} />}
        <_Btn
          onClick={cb.onBlockQuote}
          active={editor.isActive('blockquote')}
          icon={faQuoteLeft}
        />
      </div>
    )
  }
)

