import { useMemo } from 'react'
import { graphql, useSubscription } from 'react-relay'
import { NotDeletedComment } from './Comment'
import { UseSubCommentLikesAggregateSubscription } from './__generated__/UseSubCommentLikesAggregateSubscription.graphql'

const subscription = graphql`
  subscription UseSubCommentLikesAggregateSubscription(
    $commentKey: CommentKeyIn!
  ) {
    commentLikesAggregate(commentKey: $commentKey) {
      id
      likeCount
    }
  }
`

export function useSubCommentLikesAggregate(comment: NotDeletedComment) {
  const {userId, commentId} = comment.commentKey
  useSubscription<UseSubCommentLikesAggregateSubscription>(
    useMemo(
      () => ({subscription, variables: {commentKey: {userId, commentId}}}),
      [commentId, userId]
    )
  )
}
