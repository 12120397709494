import {API} from '@indieocean/apidef'
import {assert, noCase} from '@indieocean/utils'
import {Editor, mergeAttributes, Node} from '@tiptap/core'
import {NodeViewWrapper, ReactNodeViewRenderer} from '@tiptap/react'
import React from 'react'
import {Cover} from '../../../../Common/Cover'
import {AppLink} from '../../../../Common/Tools/AppLink'
import {useBooleanStateObj} from '../../../../Utils/UseBooleanStateObj'
import {BookUtils} from '../../../Store/Book/BookUtils'
import {Book, isBookDeleted, isBookLive} from '../../../Store/Book/Home/Book'
import {EditorBookControl} from './EditorBookControl'

export type EditorBookFloatNodeOptions = {
  HTMLAttributes: Record<string, any>
  books: Map<string, Book>
}

export const EditorBookFloatNode = Node.create<EditorBookFloatNodeOptions>({
  name: 'bookFloat',
  priority: 100,
  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      userId: {default: ''},
      bookId: {default: ''},
    }
  },

  parseHTML() {
    return [{tag: 'div[data-bookFloat]'}]
  },
  renderHTML({HTMLAttributes, node}) {
    return [
      'div',
      mergeAttributes(
        {'data-bookFloat': ''},
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(_Component)
  },
})

const _Component = React.memo(
  ({
    node,
    extension,
    editor,
    deleteNode,
  }: {
    node: any
    extension: Node<EditorBookFloatNodeOptions>
    editor: Editor
    deleteNode: () => void
  }) => {
    const {books} = extension.options
    const userBookId = API.readUserBookIdFromEditorNodeAttrs(node.attrs)
    const userBookIdKey = API.GQLIds.book(userBookId)
    const book = books.get(userBookIdKey)
    assert(book)
    assert(isBookLive(book) || isBookDeleted(book))
    const controlOpen = useBooleanStateObj(false)
    const andFocus = (fn: () => void) => () => {
      fn()
      window.setTimeout(() => editor.chain().focus().run(), 0)
    }

    const price = (() => {
      switch (book.data.__typename) {
        case 'BookDataDeleted':
          return null
        case 'BookDataLive':
          return book.data.info.purchasingDetails
            ? BookUtils.Price.lower(book.data.info.purchasingDetails)
            : null
        default:
          noCase(book.data)
      }
    })()

    return (
      <NodeViewWrapper
        className={`float-left mt-2 mr-4 mb-2 cursor-pointer flex flex-col items-center`}
        onClick={editor.isEditable ? controlOpen.setTrue : undefined}
      >
        <Cover
          cover={book.data.info.cover}
          size="w-[75px]"
          href={
            editor.isEditable ? undefined : BookUtils.inlinePathIfLive(book)
          }
        />
        <AppLink
          path={editor.isEditable ? null : BookUtils.path(book).root()}
          className="a-btn btn-dark text-sm py-1 px-2 w-full mt-1"
        >
          Buy{' '}
          {price !== null && (
            <span className="text-xs">{BookUtils.Price.format(price)}</span>
          )}
        </AppLink>
        {controlOpen.value && (
          <EditorBookControl
            bookPath={BookUtils.inlinePathIfLive(book)}
            onCancel={andFocus(controlOpen.setFalse)}
            onDelete={deleteNode}
          />
        )}
      </NodeViewWrapper>
    )
  }
)
