import {faComment, faEllipsisH, faHeart} from '@fortawesome/pro-light-svg-icons'
import {faHeart as faHeartSolid} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import {AppLink} from '../../../Common/Tools/AppLink'
import {MenuButton} from '../../../Common/Tools/MenuButton'
import {useUser} from '../../../Common/WithUser'
import {commentPath, NotDeletedComment} from './Comment'
import {CommentToggleLike} from './CommentToggleLike'
import {DeleteComment} from './DeleteComment'

export const CommentActionBar = React.memo(
  ({
    onReply,
    className = '',
    comment,
  }: {
    onReply: () => void
    className?: string
    comment: NotDeletedComment
  }) => {
    const user = useUser()
    const isOwn = comment.commentKey.userId === user?.userId
    return (
      <div className={`${className} flex items-center text-lg`}>
        <CommentToggleLike comment={comment}>
          {handleLike => (
            <button className={` pl-1 pr-2`} onClick={handleLike}>
              <FontAwesomeIcon
                className=" mr-2"
                icon={
                  comment.data.likes.relativeToStore?.isLiked
                    ? faHeartSolid
                    : faHeart
                }
              />
            </button>
          )}
        </CommentToggleLike>
        <AppLink className={`pr-4`} path={commentPath(comment).likes}>
          {comment.data.likes.aggregate.likeCount}
        </AppLink>
        <button className="px-5  py-1" onClick={onReply}>
          <FontAwesomeIcon icon={faComment} />
        </button>
        {isOwn && (
          <DeleteComment comment={comment} user={user}>
            {handleDelete => (
              <MenuButton className="px-3" origin="bottomStart" overlap>
                <FontAwesomeIcon className="text-xl" icon={faEllipsisH} />
                {[{text: 'Delete', action: handleDelete}]}
              </MenuButton>
            )}
          </DeleteComment>
        )}
      </div>
    )
  }
)
