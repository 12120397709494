import {assert, fGet, FGet, noCase} from '@indieocean/utils'
import _ from 'lodash'
import Head from 'next/head'
import React from 'react'
import {graphql, useFragment, useLazyLoadQuery} from 'react-relay'
import {GQL} from '../../../../../Generated/GQL/TypesFromSDL'
import StorePage from '../../../../Common/Page/StorePage'
import {useBasicStoreLive} from '../../../../Common/Page/WithBasicStoreLive'
import {GQLFetchError} from '../../../../Common/WithRelay'
import {useUser} from '../../../../Common/WithUser'
import {commentsQueryArgs} from '../../../Common/Comments/Comments'
import {useStoreSlugURLParam} from '../../UseStoreSlugURLParam'
import {useBookSlugOrIdURLParam} from '../UseBookSlugOrIdURLParam'
import {BookAfterData} from './BookAfterData'
import {BookQuery} from './__generated__/BookQuery.graphql'
import {
  Book_query$data,
  Book_query$key,
} from './__generated__/Book_query.graphql'

const query = graphql`
  query BookQuery(
    $storeSlug: String!
    $bookSlug: String
    $bookId: String
    $userId: String
    $commentsStartCursor: String!
    $commentsPageSize: Int!
  ) {
    ...StorePage_query
    ...Book_query
  }
`

export const Book = React.memo(() => {
  const userId = useUser()?.userId
  const storeSlug = useStoreSlugURLParam()
  const data = useLazyLoadQuery<BookQuery>(query, {
    storeSlug,
    ...useBookSlugOrIdURLParam(),
    userId,
    ...commentsQueryArgs,
  })

  return (
    <StorePage queryDataKey={data} header={{type: 'store', size: 'split'}}>
      <_AfterData queryDataKey={data} />
    </StorePage>
  )
})

const queryFragment = graphql`
  fragment Book_query on Query {
    store(slug: $storeSlug) {
      id
      data {
        book(slug: $bookSlug, bookId: $bookId) {
          id
          ...Book_book @relay(mask: false)
          data {
            __typename
            ... on BookDataLive {
              comments {
                ...Comments_comments
              }
            }
          }
        }
      }
    }
  }
`

graphql`
  fragment Book_book on Book {
    __typename
    id
    userId
    bookId
    bookKey {
      bookId
      userId
    }
    timeAdded
    store {
      id
      slug
      name
      # Get data because it makes useUpdateCacheForBookThroughSlug easier.
      data {
        __typename
        links {
          twitter {
            username
          }
        }
      }
    }
    rating
    description
    private {
      identification {
        titleAndAuthor
      }
    }
    data {
      __typename
      ... on BookDataPending {
        sectionId
      }
      ... on BookDataRejected {
        timeWhenRejected
        sectionId
        reason
      }
      ... on BookDataDeleted {
        info {
          slug
          cover {
            url
            aspectRatio
          }
          title
          subtitle
          authors
        }
      }
      ... on BookDataLive {
        sectionId
        info {
          slug
          title
          subtitle
          authors
          cover {
            url
            aspectRatio
          }
          amazonURL
          purchasingDetails {
            variants {
              paperback {
                price
              }
              hardcover {
                price
              }
            }
            shippingCost {
              freePriority
            }
          }
        }
        likes {
          id
          aggregate {
            id
            likeCount
          }
          relativeToStore(userId: $userId) {
            isLiked
          }
        }
        comments {
          id
          count
        }
        review {
          id
          postId
          createdTime
          postData: data {
            lastEditedTime
            stage {
              __typename
              ... on ReviewStagePublished {
                publishedTime
              }
            }
            snippet
          }
        }
      }
    }
  }
`

export type Book = GQL.Book_Book
export type BookLive = Omit<Book, 'data'> & {
  data: Extract<Book['data'], {__typename: 'BookDataLive'}>
}
export type BookPending = Omit<Book, 'data'> & {
  data: Extract<Book['data'], {__typename: 'BookDataPending'}>
}
export type BookWithdrawn = Omit<Book, 'data'> & {
  data: Extract<Book['data'], {__typename: 'BookDataWithdrawn'}>
}
export type BookRejected = Omit<Book, 'data'> & {
  data: Extract<Book['data'], {__typename: 'BookDataRejected'}>
}
export type BookDeleted = Omit<Book, 'data'> & {
  data: Extract<Book['data'], {__typename: 'BookDataDeleted'}>
}
export type BookVisibleInAdmin = BookLive | BookPending | BookRejected

export type BookAdmin = Omit<Book, 'private'> & {
  private: FGet<Book['private']>
}
type _BookLiveDataInfoAvailable = Omit<
  BookLive['data']['info'],
  'purchasingDetails'
> & {
  purchasingDetails: FGet<BookLive['data']['info']['purchasingDetails']>
}
type _BookLiveDataAvailable = Omit<BookLive['data'], 'info'> & {
  info: _BookLiveDataInfoAvailable
}
export type BookLiveAvailable = Omit<BookLive, 'data'> & {
  data: _BookLiveDataAvailable
}

export const isBookLive = (book: Book): book is BookLive =>
  book.data.__typename === 'BookDataLive'
export const isBookPending = (book: Book): book is BookPending =>
  book.data.__typename === 'BookDataPending'
export const isBookRejected = (book: Book): book is BookRejected =>
  book.data.__typename === 'BookDataRejected'
export const isBookDeleted = (book: Book): book is BookDeleted =>
  book.data.__typename === 'BookDataDeleted'
export const isBookVisibleInAdmin = (
  book: Book
): book is BookVisibleInAdmin => {
  switch (book.data.__typename) {
    case 'BookDataPending':
    case 'BookDataRejected':
    case 'BookDataLive':
      return true
    case 'BookDataWithdrawn':
    case 'BookDataDeleted':
      return false
    default:
      noCase(book.data)
  }
}
export const isBookLiveAvailable = (
  book: BookLive
): book is BookLiveAvailable => book.data.info.purchasingDetails !== null
export const isBookOwn = (
  book: Book,
  userId: string | null
): book is BookAdmin => {
  if (book.userId !== userId) return false
  assert(book.private !== null)
  return true
}
// Generally you should use isBookOwn because you should filter access
// based on book ownership, not access, but if you have not implemented that,
// use isBookAdmin for the time being.
export const isBookAdmin = (book: Book): book is BookAdmin =>
  book.private !== null

function _commentsKey(data: Book_query$data) {
  const book = fGet(data.store?.data?.book)
  assert(book.data.__typename === 'BookDataLive')
  return book.data.comments
}

const _AfterData = React.memo(
  ({queryDataKey}: {queryDataKey: Book_query$key}) => {
    const data = useFragment(
      queryFragment,
      queryDataKey
    ) 
    const {store} = data as unknown as GQL.Book_Query
    if (!store?.data?.book) throw new GQLFetchError('NotFound')
    const basicStore = useBasicStoreLive()
    const book = store.data.book
    if (!isBookLive(book)) {
      // No UI (yet) for deleted, pending, rejected, etc.
      throw new GQLFetchError('NotFound')
    }
    const titleTruncated = _.truncate(book.data.info.title, {length: 30})
    const pageTitle = `${titleTruncated} from ${basicStore.name} on IndieOcean`

    return (
      <>
        <Head>
          <title>{pageTitle}</title>
        </Head>
        <BookAfterData book={book} commentsKey={_commentsKey(data)}/>
      </>
    )
  }
)
