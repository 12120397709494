import { API } from '@indieocean/apidef'
import { assert, noCase } from '@indieocean/utils'
import Blockquote from '@tiptap/extension-blockquote'
import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import Document from '@tiptap/extension-document'
import DropCursor from '@tiptap/extension-dropcursor'
import History from '@tiptap/extension-history'
import Italic from '@tiptap/extension-italic'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import Strike from '@tiptap/extension-strike'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Text from '@tiptap/extension-text'
import Typography from '@tiptap/extension-typography'
import {
  ChainedCommands,
  Editor as TipTapEditor,
  findParentNode,
  useEditor
} from '@tiptap/react'
import _ from 'lodash'
import router from 'next/router'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useRelayEnvironment } from 'react-relay'
import { fetchQuery, graphql } from 'relay-runtime'
import { Config } from '../../../../Config'
import { GQL } from '../../../../Generated/GQL/TypesFromSDL'
import { BasicStore } from '../../../Common/Page/WithBasicStoreLive'
import { useGlobalToasts } from '../../../Common/WithGlobalToasts'
import { assertStateMatch } from '../../../Common/WithRelay'
import {
  BooleanStateObj,
  useBooleanStateObj
} from '../../../Utils/UseBooleanStateObj'
import { useStateObj } from '../../../Utils/UseStateObj'
import { Book, isBookLive } from '../../Store/Book/Home/Book'
import { EditorBookBlockNode } from './CustomNodes/EditorBookBlockNode'
import { EditorBookFloatNode } from './CustomNodes/EditorBookFloatNode'
import { EditorBookInlineNode } from './CustomNodes/EditorBookInlineNode'
import { EditorHeadingNode } from './CustomNodes/EditorHeadingNode'
import { EditorImageNode } from './CustomNodes/EditorImageNode'
import { EditorLinkNode } from './CustomNodes/EditorLinkNode'
import { EditorStoreNode } from './CustomNodes/EditorStoreNode'
import { getSnippetFromEditor } from './GetSnippetFromEditor'
import { EditorBookInput } from './Input/EditorBookInput'
import { EditorImageInput } from './Input/EditorImageInput'
import { EditorLinkInput } from './Input/EditorLinkInput'
import { EditorStoreInput } from './Input/EditorStoreInput'
import { EditorQuery } from './__generated__/EditorQuery.graphql'

const bookQuery = graphql`
  query EditorQuery($userId: String!, $bookId: String!) {
    user(userId: $userId) {
      id
      store {
        id
        data {
          book(bookId: $bookId) {
            id
            ...Book_book @relay(mask: false)
          }
        }
      }
    }
  }
`
export type EditorRenderProps = {
  isRunningState: BooleanStateObj
  callbacks: ReturnType<typeof _callbacks>
  editor: TipTapEditor
  getSnippet: (length: number) => string
  contentStr: () => string
  isSaved: boolean
  type: 'fullPage' | 'compact' | 'minimal'
}

// Changes to books and stores are ignored, which means out-of-band changes that
// add books or stores are not allowed (and will throw) because content needs to
// change to check isSaved.
export const Editor = React.memo(
  ({
    userIdOfPostAuthor,
    data,
    editable,
    type,
    isExternalSaved,
    children,
  }: {
    userIdOfPostAuthor: string
    data: {books: Book[]; stores: BasicStore[]; content: string}
    editable: boolean
    type: 'fullPage' | 'compact' | 'minimal'
    isExternalSaved: boolean
    children: (props: EditorRenderProps) => ReactNode
  }) => {
    const imagePathFn = (filename: string) =>
      Config.client.google.storage.buckets.data
        .user(userIdOfPostAuthor)
        .postImage(filename)

    const [books] = useState(
      () => new Map(data.books.map(x => [API.GQLIds.book(x), x]))
    )
    const [stores] = useState(
      () =>
        new Map<string, BasicStore | API.SearchDataStore>(
          data.stores.map(x => [x.user.userId, x])
        )
    )
    const savedContent = useMemo(() => JSON.parse(data.content), [data.content])
    const contentState = useStateObj(savedContent)
    const isContentSaved = useMemo(
      () => _.isEqual(savedContent, contentState.value),
      [savedContent, contentState.value]
    )
    const isRunningState = useBooleanStateObj(false)

    const editor = useEditor({
      editable,
      extensions: _.compact([
        // Custom
        EditorBookInlineNode.configure({
          HTMLAttributes: {class: 'bg-gray-100 px-1 rounded-sm'},
          books,
        }),

        type === 'fullPage' || type === 'compact'
          ? EditorBookBlockNode.configure({
              HTMLAttributes: {class: ''},
              books,
            })
          : undefined,

        type === 'fullPage' || type === 'compact'
          ? EditorBookFloatNode.configure({
              HTMLAttributes: {class: ''},
              books,
            })
          : undefined,
        EditorStoreNode.configure({
          HTMLAttributes: {class: 'bg-gray-100 px-1 rounded-sm '},
          stores,
        }),
        EditorLinkNode.configure({}),
        type === 'fullPage' || type === 'compact'
          ? EditorImageNode.configure({
              imagePathFn,
              HTMLAttributes: {class: ' '},
            })
          : undefined,
        type === 'fullPage'
          ? EditorHeadingNode.configure({
              HTMLAttributes: {class: 'font-bold'},
              levels: [1, 2],
            })
          : undefined,
        // Nodes
        Document,
        Paragraph.configure({
          HTMLAttributes: {
            class: `font-karla 
            ${type === 'fullPage' ? 'text-lg' : 'text-base'}
            ${type === 'fullPage' ? 'mb-3' : ''}
            ${type === 'compact' ? 'mb-2' : ''}
            ${type === 'minimal' ? 'mb-1' : ''}
            `,
          },
        }),
        Text,
        type === 'fullPage'
          ? BulletList.configure({
              HTMLAttributes: {class: 'list-disc list-outside pl-4'},
            })
          : undefined,
        type === 'fullPage'
          ? OrderedList.configure({
              HTMLAttributes: {class: 'list-decimal list-outside pl-4'},
            })
          : undefined,
        ListItem,
        Blockquote.configure({
          HTMLAttributes: {
            class: 'ml-5 pl-5 lighten border-l-2 border-gray-400',
          },
        }),

        // Marks
        Bold.configure({HTMLAttributes: {class: 'font-semibold'}}),
        Italic,
        Strike,
        Subscript,
        Superscript,

        // Extensions
        DropCursor,
        History,
        Placeholder.configure({
          emptyEditorClass: 'lighten-2',
          placeholder: 'Share your thoughts...',
        }),
        Typography,
        // Utilities
      ]),
      content: contentState.value,
      editorProps: {
        attributes: {
          class: 'outline-none',
        },
      },
      onUpdate: ({editor}) => contentState.set(editor.getJSON()),
    })

    const isSaved = isContentSaved && isExternalSaved
    useEffect(() => {
      if (isSaved) return
      const callback = (e: BeforeUnloadEvent) => {
        e.preventDefault()
        e.returnValue = ''
        return true
      }
      const nextCallback = () => {
        const msg = 'There are unsaved changes. Are you sure you want to leave?'
        if (window.confirm(msg)) {
          return
        } else {
          router.events.emit('routeChangeError')
          throw 'routeChange aborted.'
        }
      }
      router.events.on('routeChangeStart', nextCallback)
      window.addEventListener('beforeunload', callback)
      return () => {
        router.events.off('routeChangeStart', nextCallback)
        window.removeEventListener('beforeunload', callback)
      }
    }, [isSaved])

    const relayEnv = useRelayEnvironment()
    const {errorToast} = useGlobalToasts()
    const isLinkInputOpen = useBooleanStateObj(false)
    const isImageInputOpen = useBooleanStateObj(false)
    const isBookInputOpen = useBooleanStateObj(false)
    const isStoreInputOpen = useBooleanStateObj(false)

    if (!editor) return <></>

    return (
      <>
        {children({
          editor,
          callbacks: _callbacks(editor, {
            onLink: isLinkInputOpen.setTrue,
            onImage: isImageInputOpen.setTrue,
            onBook: isBookInputOpen.setTrue,
            onStore: isStoreInputOpen.setTrue,
          }),
          isRunningState,
          getSnippet: (length: number) =>
            getSnippetFromEditor(editor, books, stores, length),
          contentStr: () => JSON.stringify(contentState.value),
          isSaved,
          type,
        })}
        {isLinkInputOpen.value && (
          <EditorLinkInput
            text={_getSelectedText(editor)}
            onCancel={() => {
              isLinkInputOpen.setFalse()
              window.setTimeout(() => editor.chain().focus().run(), 0)
            }}
            onLink={(text, href) => {
              isLinkInputOpen.setFalse()
              const content = {type: 'link', attrs: {href, text}}
              editor.chain().focus().insertContent(content).run()
            }}
          />
        )}
        {isImageInputOpen.value && (
          <EditorImageInput
            onCancel={() => {
              isImageInputOpen.setFalse()
              window.setTimeout(() => editor.chain().focus().run(), 0)
            }}
            onImage={(filename, size, caption) => {
              isImageInputOpen.setFalse()
              const content = {
                type: 'image',
                attrs: {filename, size, caption},
              }
              editor.chain().focus().insertContent(content).run()
            }}
          />
        )}
        {isStoreInputOpen.value && (
          <EditorStoreInput
            onCancel={() => {
              isStoreInputOpen.setFalse()
              window.setTimeout(() => editor.chain().focus().run(), 0)
            }}
            onStore={store => {
              isStoreInputOpen.setFalse()
              stores.set(store.user.userId, store)

              const content = {
                type: 'store',
                attrs: {userId: store.user.userId},
              }
              editor.chain().focus().insertContent(content).run()
            }}
          />
        )}
        {isBookInputOpen.value && (
          <EditorBookInput
            inlineOnly={type === 'minimal'}
            onCancel={() => {
              isBookInputOpen.setFalse()
              window.setTimeout(() => editor.chain().focus().run(), 0)
            }}
            onBook={({userId, bookId}, type) => {
              isBookInputOpen.setFalse()

              // Timeout because otherwise it complains about no focusable
              // element in focus trap.
              window.setTimeout(() => {
                fetchQuery<EditorQuery>(relayEnv, bookQuery, {
                  userId,
                  bookId,
                }).subscribe({
                  start: () => {
                    assert(!isRunningState.value)
                    isRunningState.setTrue()
                  },
                  next: dataIn => {
                    const data = dataIn as unknown as GQL.EditorQuery
                    assert(data.user.store?.data)
                    const {book} = data.user.store.data
                    assertStateMatch(book && isBookLive(book))
                    const userBookIdKey = API.GQLIds.book({userId, bookId})
                    books.set(userBookIdKey, book)

                    const attrs = {userId, bookId}
                    const content =
                      type === 'title'
                        ? {
                            type: 'bookInline',
                            attrs: {...attrs, includeAuthor: false},
                          }
                        : type === 'titleAndAuthor'
                        ? {
                            type: 'bookInline',
                            attrs: {...attrs, includeAuthor: true},
                          }
                        : type === 'float'
                        ? {type: 'bookFloat', attrs}
                        : type === 'block'
                        ? {type: 'bookBlock', attrs}
                        : noCase(type)

                    if (type === 'float') {
                      const parentP = findParentNode(
                        node => node.type.name === 'paragraph'
                      )(editor.state.selection)
                      if (!parentP) {
                        errorToast('Please move the cursor to a paragraph.')
                      } else {
                        editor
                          .chain()
                          .focus()
                          .insertContentAt(parentP.start, content)
                          .run()
                      }
                    } else {
                      editor.chain().focus().insertContent(content).run()
                    }
                  },
                  complete: isRunningState.setFalse,
                  error: () => {
                    errorToast('Something went wrong.')
                    isRunningState.setFalse()
                  },
                })
              }, 0)
            }}
          />
        )}
      </>
    )
  }
)

function _getSelectedText(editor: TipTapEditor) {
  const {from, to} = editor.state.selection
  return editor.state.doc.textBetween(from, to, ' ')
}

function _callbacks(
  editor: TipTapEditor,
  inputCallbacks: {
    onLink: () => void
    onBook: () => void
    onStore: () => void
    onImage: () => void
  }
) {
  const _wrap = (fn: (chain: ChainedCommands) => ChainedCommands) => () => {
    window.setTimeout(() => fn(editor.chain()).focus().run(), 0)
  }

  return {
    onTextSize: () => {
      const attributes =
        editor.getAttributes('heading') ?? editor.getAttributes('paragraph')
      if ('level' in attributes) {
        if (attributes.level === 1) {
          editor.chain().setHeading({level: 2}).focus().run()
        } else {
          editor.chain().setParagraph().focus().run()
        }
      } else {
        editor.chain().setHeading({level: 1}).focus().run()
      }
    },
    onBold: _wrap(x => x.toggleBold()),
    onItalic: _wrap(x => x.toggleItalic()),
    onBlockQuote: _wrap(x => x.toggleBlockquote()),
    onSubscript: _wrap(x => x.toggleSubscript()),
    onSuperscript: _wrap(x => x.toggleSuperscript()),
    onBulletList: _wrap(x => x.toggleBulletList()),
    onOrderedList: _wrap(x => x.toggleOrderedList()),
    onStrike: _wrap(x => x.toggleStrike()),
    onRefocus: _wrap(x => x),
    onCursorToEnd: _wrap(x => x.setTextSelection(Number.MAX_SAFE_INTEGER)),
    ...inputCallbacks,
  }
}
