/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UseSubBookLikesAggregateSubscriptionVariables = {
    userId: string;
    bookId: string;
};
export type UseSubBookLikesAggregateSubscriptionResponse = {
    readonly bookLikesAggregate: {
        readonly id: string;
        readonly likeCount: number;
    };
};
export type UseSubBookLikesAggregateSubscription = {
    readonly response: UseSubBookLikesAggregateSubscriptionResponse;
    readonly variables: UseSubBookLikesAggregateSubscriptionVariables;
};



/*
subscription UseSubBookLikesAggregateSubscription(
  $userId: String!
  $bookId: String!
) {
  bookLikesAggregate(userId: $userId, bookId: $bookId) {
    id
    likeCount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bookId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bookId",
        "variableName": "bookId"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "BookLikesAggregate",
    "kind": "LinkedField",
    "name": "bookLikesAggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "likeCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UseSubBookLikesAggregateSubscription",
    "selections": (v2/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UseSubBookLikesAggregateSubscription",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4e940b749b91bf93add6317ec2e7e617",
    "id": null,
    "metadata": {},
    "name": "UseSubBookLikesAggregateSubscription",
    "operationKind": "subscription",
    "text": "subscription UseSubBookLikesAggregateSubscription(\n  $userId: String!\n  $bookId: String!\n) {\n  bookLikesAggregate(userId: $userId, bookId: $bookId) {\n    id\n    likeCount\n  }\n}\n"
  }
};
})();
(node as any).hash = '27252151437e8b5526dee0bf27ef004d';
export default node;
