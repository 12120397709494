import { API } from '@indieocean/apidef'
import { assert, FGet, fGet, noCase } from '@indieocean/utils'
import _ from 'lodash'
import {
  Book,
  BookDeleted,
  BookLive,
  BookPending,
  BookRejected,
  BookWithdrawn,
  isBookLive
} from './Home/Book'

export namespace BookUtils {
  export namespace Price {
    export function format(price: number) {
      return `$${(price / 100).toFixed(2)}`
    }

    export function formatShipping(price: number) {
      return price === 0 ? 'FREE' : format(price)
    }

    export const lower = ({
      variants: {hardcover, paperback},
    }: FGet<BookLive['data']['info']['purchasingDetails']>) =>
      Math.min(..._.compact<number>([hardcover?.price, paperback?.price]))

    export const formatLower = (
      purchasingDetails: FGet<BookLive['data']['info']['purchasingDetails']>
    ) => format(lower(purchasingDetails))
  }

  export namespace Authors {
    export function toStr(authors: string[]) {
      assert(authors.length > 0)
      if (authors.length === 1) return authors[0]
      if (authors.length === 2) return authors.join(' and ')
      return `${authors.slice(0, authors.length - 1).join(', ')}, and ${fGet(
        _.last(authors)
      )}`
    }
    export const etAlStr = (
      args: string[] | {data: {info: {authors: string[]}}}
    ): string =>
      'data' in args
        ? etAlStr(args.data.info.authors)
        : args.length <= 2
        ? toStr(args)
        : `${args[0]} et al.`
  }

  export const pathFnDepr =
    (book: BookLive | BookDeleted) => (prefer: 'detail' | 'inline') =>
      pathDepr(book, prefer)

  export const pathDepr = (
    book: BookLive | BookDeleted,
    prefer: 'detail' | 'inline'
  ) => {
    if (prefer === 'detail') return `/${book.store.slug}/${book.data.info.slug}`
    switch (book.data.__typename) {
      case 'BookDataLive':
        return `/${book.store.slug}?scrollTo=book-${book.bookId}`
      case 'BookDataDeleted':
        return `/${book.store.slug}/${book.data.info.slug}`
      default:
        noCase(book.data)
    }
  }

  export const slugOrId = (book: Book) => {
    switch (book.data.__typename) {
      case 'BookDataPending':
      case 'BookDataWithdrawn':
      case 'BookDataRejected':
        return {bookId: book.bookId}
      case 'BookDataLive':
      case 'BookDataDeleted':
        return {bookSlug: book.data.info.slug}
      default:
        noCase(book.data)
    }
  }
  export const path = (book: Book) =>
    API.Path.store(book.store.slug).book(slugOrId(book))

  export const inlinePath = (
    book: BookLive | BookPending | BookWithdrawn | BookRejected
  ) =>
    API.Path.store(book.store.slug).books({
      scrollTo: `book-${book.bookId}`,
    })

  export const inlinePathIfLive = (book: BookLive | BookDeleted) => {
    switch (book.data.__typename) {
      case 'BookDataLive':
        assert(isBookLive(book))
        return inlinePath(book)
      case 'BookDataDeleted':
        return path(book).root()
      default:
        noCase(book.data)
    }
  }
}
