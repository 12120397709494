/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type BookLikeArgs = {
    targetKey: BookKeyIn;
    userId: string;
};
export type BookKeyIn = {
    bookId: string;
    userId: string;
};
export type UseBookToggleLikeUndoMutationVariables = {
    args: BookLikeArgs;
    userId: string;
};
export type UseBookToggleLikeUndoMutationResponse = {
    readonly bookUndoLike: {
        readonly id: string;
        readonly aggregate: {
            readonly id: string;
            readonly likeCount: number;
        };
        readonly relativeToStore: {
            readonly isLiked: boolean;
        } | null;
    };
};
export type UseBookToggleLikeUndoMutation = {
    readonly response: UseBookToggleLikeUndoMutationResponse;
    readonly variables: UseBookToggleLikeUndoMutationVariables;
};



/*
mutation UseBookToggleLikeUndoMutation(
  $args: BookLikeArgs!
  $userId: String!
) {
  bookUndoLike(args: $args) {
    id
    aggregate {
      id
      likeCount
    }
    relativeToStore(userId: $userId) {
      isLiked
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "args"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "args",
        "variableName": "args"
      }
    ],
    "concreteType": "BookLikes",
    "kind": "LinkedField",
    "name": "bookUndoLike",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BookLikesAggregate",
        "kind": "LinkedField",
        "name": "aggregate",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "likeCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "userId",
            "variableName": "userId"
          }
        ],
        "concreteType": "BookLikesRelativeToStore",
        "kind": "LinkedField",
        "name": "relativeToStore",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLiked",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UseBookToggleLikeUndoMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UseBookToggleLikeUndoMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7ce5b57335af3a4c1157fff6e1cc6978",
    "id": null,
    "metadata": {},
    "name": "UseBookToggleLikeUndoMutation",
    "operationKind": "mutation",
    "text": "mutation UseBookToggleLikeUndoMutation(\n  $args: BookLikeArgs!\n  $userId: String!\n) {\n  bookUndoLike(args: $args) {\n    id\n    aggregate {\n      id\n      likeCount\n    }\n    relativeToStore(userId: $userId) {\n      isLiked\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '023e44a58bf6c0028848b0301ac87075';
export default node;
