import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {API} from '@indieocean/apidef'
import {noCase} from '@indieocean/utils'
import _ from 'lodash'
import React, {CSSProperties, ReactNode, useMemo, useState} from 'react'
import ReactDOM from 'react-dom'
import {usePopper} from 'react-popper'
import {AppLink} from './AppLink'

// HeadlessUI Menu was not auto closing on mouse click on menu item.

export type MenuButtonProps = {
  origin: 'bottomStart' | 'topStart' | 'bottomEnd' | 'topEnd'
  className?: string
  overlap?: boolean
  menuClassName?: string
  style?: CSSProperties
  children: [
    ReactNode,
    (MenuButtonItemProps | undefined | ((onClose: () => void) => ReactNode))[]
  ]
}
export const MenuButton = React.memo(
  ({
    origin,
    overlap = false,
    className,
    menuClassName = 'bg-pageBG border-2 border-inputBorder text-pageFG pt-1.5 pb-1.5 text-lg shadow-xl rounded-md',
    style,
    children: [buttonContent, menuItems],
  }: MenuButtonProps) => {
    const [placement, textAlign] =
      origin === 'bottomStart'
        ? (['bottom-start', 'text-left'] as const)
        : origin === 'topStart'
        ? (['top-start', 'text-left'] as const)
        : origin === 'topEnd'
        ? (['top-end', 'text-right'] as const)
        : origin === 'bottomEnd'
        ? (['bottom-end', 'text-right'] as const)
        : noCase(origin)

    const offsetFn = useMemo(
      () =>
        ({reference}: {reference: {height: number}}) => {
          return [0, reference.height * (overlap ? -1 : 0)] as [number, number]
        },
      [overlap]
    )

    const [referenceElement, setReferenceElement] =
      useState<HTMLElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
      placement,
      modifiers: [{name: 'offset', options: {offset: offsetFn}}],
    })

    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)

    return (
      <>
        <button
          className={className}
          style={style}
          onClick={() => setOpen(true)}
          ref={setReferenceElement}
        >
          {buttonContent}
        </button>
        {open &&
          ReactDOM.createPortal(
            <div
              className="fixed w-screen h-screen top-0 left-0 z-50 font-mont"
              onClick={handleClose}
            >
              <div
                className={`${menuClassName} ${textAlign}`}
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                {_.compact(menuItems).map((props, i) =>
                  typeof props === 'function' ? (
                    props(handleClose)
                  ) : (
                    <MenuButtonItem key={i} {...props} onClose={handleClose} />
                  )
                )}
              </div>
            </div>,
            document.body
          )}
      </>
    )
  }
)

export type MenuButtonItemProps = {
  faIcon?: IconProp
  text: string
  action: API.Path | (() => void) | string
}

export const MenuButtonItem = React.memo(
  ({
    faIcon,
    text,
    action,
  }: MenuButtonItemProps & {
    onClose: () => void
  }) => {
    const className = `block w-full pt-3 pb-3 vertNav:pt-1.5 vertNav:pb-1.5 pl-4 pr-4 text-lg  whitespace-nowrap 
    }`
    const icon = faIcon && <FontAwesomeIcon className="mr-2" icon={faIcon} />

    return typeof action === 'function' ? (
      <button
        className={`${className}`}
        onClick={action}
        style={{textAlign: 'inherit'}}
      >
        {icon}
        {text}
      </button>
    ) : typeof action === 'string' ? (
      <a className={className} href={action} target="_blank">
        {icon}
        {text}
      </a>
    ) : (
      <AppLink path={action} className={className}>
        {icon}
        {text}
      </AppLink>
    )
  }
)
