import {mergeAttributes} from '@tiptap/core'
import Heading from '@tiptap/extension-heading'

export const EditorHeadingNode = Heading.extend({
  renderHTML({node, HTMLAttributes}) {
    const hasLevel = this.options.levels.includes(node.attrs.level)
    const level = hasLevel ? node.attrs.level : this.options.levels[0]

    return [
      `h${level}`,
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: level === 1 ? 'text-2xl  mb-5' : 'text-xl mb-3',
      }),
      0,
    ]
  },
})
