import {fGet} from '@indieocean/utils'
import {ReactNode} from 'react'
import {graphql} from 'relay-runtime'
import { useCreateStoreOrAction } from '../../../Common/Tools/UseCreateStoreOrAction'
import { useGQLMutation } from '../../../Common/Tools/UseGQLMutation'
import { useGlobalToasts } from '../../../Common/WithGlobalToasts'
import { NotDeletedComment } from './Comment'
import { useSubCommentLikesAggregate } from './UseSubCommentLikesAggregate'

import {
  CommentToggleLikeMutation,
  CommentToggleLikeMutationResponse,
} from './__generated__/CommentToggleLikeMutation.graphql'
import {
  CommentToggleLikeUndoMutation,
  CommentToggleLikeUndoMutationResponse,
} from './__generated__/CommentToggleLikeUndoMutation.graphql'

const likeMutation = graphql`
  mutation CommentToggleLikeMutation($args: CommentLikeArgs!, $userId: String!) {
    commentLike(args: $args) {
      id
      aggregate {
        id
        likeCount
      }
      relativeToStore(userId: $userId) {
        isLiked
      }
    }
  }
`

const undoLikeMutation = graphql`
  mutation CommentToggleLikeUndoMutation($args: CommentLikeArgs!, $userId: String!) {
    commentUndoLike(args: $args) {
      id
      aggregate {
        id
        likeCount
      }
      relativeToStore(userId: $userId) {
        isLiked
      }
    }
  }
`

export function CommentToggleLike({
  comment,
  children,
}: {
  comment: NotDeletedComment
  children: (handleToggleLike: () => void) => ReactNode
}) {
  const [commitLike] = useGQLMutation<CommentToggleLikeMutation>(
    likeMutation,
    'soft'
  )
  const [commitUndoLike] = useGQLMutation<CommentToggleLikeUndoMutation>(
    undoLikeMutation,
    'soft'
  )
  useSubCommentLikesAggregate(comment)

  const {infoToast} = useGlobalToasts()

  const handleToggleLike = useCreateStoreOrAction(user => {
    if (user.userId === comment.commentKey.userId) {
      infoToast("That's your own comment!")
      return
    }
    const {likes} = comment.data
    const {isLiked} = fGet(likes.relativeToStore)
    const targetKey = comment.commentKey
    const variables = {
      args: {userId: user.userId, targetKey},
      userId: user.userId,
    }

    const likeResponse: CommentToggleLikeMutationResponse = {
      commentLike: {
        id: likes.id,
        aggregate: {
          id: likes.aggregate.id,
          likeCount: likes.aggregate.likeCount + 1,
        },
        relativeToStore: {isLiked: true},
      },
    }

    const undoLikeResponse: CommentToggleLikeUndoMutationResponse = {
      commentUndoLike: {
        id: likes.id,
        aggregate: {
          id: likes.aggregate.id,
          likeCount: likes.aggregate.likeCount - 1,
        },
        relativeToStore: {isLiked: false},
      },
    }

    isLiked
      ? commitUndoLike({
          variables,
          optimisticResponse: undoLikeResponse,
          onCompleted: () => {},
        })
      : commitLike({
          variables,
          optimisticResponse: likeResponse,
          onCompleted: () => {},
        })
  })

  return <>{children(handleToggleLike)}</>
}
