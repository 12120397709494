import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from '@indieocean/apidef'
import { FGet, fGet } from '@indieocean/utils'
import { mergeAttributes, Node, NodeConfig } from '@tiptap/core'
import { Editor, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react'
import Link from 'next/link'
import React from 'react'
import { ModalBase } from '../../../../Common/Modal/ModalBase'
import { BasicStore } from '../../../../Common/Page/WithBasicStoreLive'
import { useAppPathFn } from '../../../../Common/Tools/UseAppPathFn'
import { useBooleanStateObj } from '../../../../Utils/UseBooleanStateObj'

export interface EditorStoreNodeOptions {
  HTMLAttributes: Record<string, any>
  stores: Map<string, BasicStore | API.SearchDataStore>
}

export const EditorStoreNode = Node.create<EditorStoreNodeOptions>({
  name: 'store',
  priority: 100,
  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      userId: {default: null},
    }
  },

  parseHTML() {
    return [{tag: 'a[data-store]'}]
  },

  renderHTML({HTMLAttributes, node}) {
    return [
      'a',
      mergeAttributes(
        {'data-store': ''},
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(_Component)
  },
})

const _Component = React.memo(
  ({
    node,
    deleteNode,
    extension,
    editor,
    updateAttributes,
  }: {
    node: Parameters<FGet<NodeConfig['renderHTML']>>[0]['node']
    deleteNode: () => void
    editor: Editor
    updateAttributes: (attrs: Record<string, any>) => void
    extension: Node<EditorStoreNodeOptions>
  }) => {
    const {userId} = API.readUserIdFromEditorNodeAttrs(node.attrs)
    const store = fGet(extension.options.stores.get(userId))
    const htmlAttrs = mergeAttributes(extension.options.HTMLAttributes)
    const className = htmlAttrs.class
    delete htmlAttrs.class
    const detailOpen = useBooleanStateObj(false)
    const pathFn = useAppPathFn()
    const storePath = API.Path.store(store.slug).root
    const andFocus = (fn: () => void) => () => {
      fn()
      window.setTimeout(() => editor.chain().focus().run(), 0)
    }
    return (
      <NodeViewWrapper className="inline">
        {editor.isEditable ? (
          // The spaces help with android bug where backspace causes duplicate text.
          <>
            {' '}
            <button className={className} onClick={detailOpen.setTrue}>
              {store.name}
            </button>{' '}
          </>
        ) : (
          <>
            {' '}
            <Link href={pathFn(storePath)}>
              <a className={className}>{store.name}</a>
            </Link>{' '}
          </>
        )}
        <ModalBase
          open={detailOpen.value}
          onClose={andFocus(detailOpen.setFalse)}
        >
          <div className="grid " style={{grid: 'auto / 1fr auto'}}>
            <Link href={pathFn(storePath)}>
              <a className="a-btn outline-none py-2 sm:py-0"> Open Link </a>
            </Link>
            <button className=" py-2 sm:py-0 px-4" onClick={deleteNode}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </ModalBase>
      </NodeViewWrapper>
    )
  }
)
